import { Box, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import Button from '../Button'
import { Link } from 'react-router-dom'
import variables from '../../settings/_variables.scss'
import IndustrySvg from '../../assets/icons/HOME_COMPANY_ICON.svg'
import IndustryWhiteSvg from '../../assets/icons/HOME_COMPANY_ICON_2.svg'
import UserSvg from '../../assets/icons/HOME_CANDIDATES_ICON.svg'
import UserWhiteSvg from '../../assets/icons/HOME_CANDIDATES_ICON_2.svg'
import { StyledIcons } from '../../base/commonStyles'
import { useTranslation } from 'react-i18next'

const HomePageHeaderButtons = props => {
    const [whiteIcon, setWhiteIcon] = useState(false)
    const [userWhiteIcon, setUserWhiteIcon] = useState(false)
    const {t} = useTranslation()
    return (
        <Box sx={{px: {xs: 1, sm: 2, md: 5}}}>
            <Grid container spacing={{xs: 1, sm: 2, md: 5}} >
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Button
                        label={
                            <Box sx={{ display: {
                                xs: 'block',
                                sm: 'flex'
                            }, alignItems: 'center' }}>
                                {!userWhiteIcon ? <StyledIcons src={UserSvg} sx={{height: 25, width: 25, mr: {
                                    xs:0, sm: 2
                                }}}/> :
                                <StyledIcons src={UserWhiteSvg} sx={{height: 25, width: 25, 
                                    mr: {
                                        xs:0, sm: 2
                                }}}/>}
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '900',
                                        fontFamily: variables.poppinsBlack,
                                    }}
                                >
                                    {t('buttons.candidateLandingpage')}
                                </Typography>
                            </Box>
                        }
                        component={Link}
                        onMouseEnter={() => setUserWhiteIcon(true)}
                        onMouseLeave={() => setUserWhiteIcon(false)}
                        to='/candidate-homepage'
                        color='secondary'
                        variant='contained'
                        size='large'
                        sx={{
                            width: '100%',
                            p: '20px 24px',
                            fontSize: '16px',
                            mr: 1,
                            minHeight:{
                                xs: '114px',
                                sm: '100px'
                            },
                            height:{
                                xs: '114px',
                                sm: '100px'
                            },
                            maxHeight:{
                                xs: '114px',
                                sm: '100px'
                            },
                            color: variables.BLUE,
                            borderRadius: '30px',
                            border: `2px solid ${variables.WHITE}`,
                            '&:hover': {
                                color: variables.WHITE,
                                background: variables.BLUE
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
                    <Button
                        label={
                            <Box sx={{ display: {
                                xs: 'block',
                                sm: 'flex'
                            }, alignItems: 'center' }}>
                                {!whiteIcon ? <StyledIcons src={IndustrySvg} sx={{height: 25, width: 25, mr: {
                                    xs:0, sm: 2
                                }}}/> :
                                <StyledIcons src={IndustryWhiteSvg} sx={{height: 25, width: 25, 
                                    mr: {
                                        xs:0, sm: 2
                                    }}}/>}
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '900',
                                        fontFamily: variables.poppinsBlack,
                                    }}
                                >
                                    {t('buttons.companyLandingPage')}
                                </Typography>
                            </Box>
                        }
                        onMouseEnter={() => setWhiteIcon(true)}
                        onMouseLeave={() => setWhiteIcon(false)}
                        component={Link}
                        to='/why-bunton'
                        color='secondary'
                        variant='contained'
                        size='large'
                        sx={{
                            width: '100%',
                            p: '20px 24px',
                            fontSize: '16px',
                            mr: 1,
                            minHeight:{
                                xs: '114px',
                                sm: '100px'
                            },
                            height:{
                                xs: '114px',
                                sm: '100px'
                            },
                            maxHeight:{
                                xs: '114px',
                                sm: '100px'
                            },
                            color: variables.BLUE,
                            borderRadius: '30px',
                            border: `2px solid ${variables.WHITE}`,
                            '&:hover': {
                                color: variables.WHITE,
                                background: variables.BLUE
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default HomePageHeaderButtons
