import { gql, useQuery } from '@apollo/client'
import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import variables from '../../settings/_variables.scss'
import Loader from '../Loader'


const GET_OUR_PARTNERS_DATA_QUERY = gql`
    query PageImages($type: String!) {
        pageImages(type: $type) {
            id
            imageUrl
            link
        }
    }
`

const OurPartners = props => {
    const { loading, error, data, fetchMore, refetch } = useQuery(GET_OUR_PARTNERS_DATA_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            type: 'our_partners'
        },
        onError(error) {}
    })
    if (loading) return <Loader />
    if (error) return <Box>{error.message}</Box>
    if(data) {
        const {pageImages} = data
        return <Box sx={{ mt: 4 }}>
            <Grid container spacing={1} justifyContent='center'>
                {pageImages.map((elem, index) => {
                    return (
                        <Grid item xs={4} sm={3} md={3} lg={2} xl={2}>
                            <Box component='a' href={elem.link} target='_blank' rel='noreferrer'>
                                <Box
                                    component='img'
                                    src={elem.imageUrl}
                                    alt={'logo'}
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        maxWidth: '197px',
                                        maxHeight: '99px',
                                        border: `2px solid ${variables.CYAN}`,
                                        borderRadius: '30px',
                                        background: variables.WHITE
                                    }}
                                />
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    }
}

export default OurPartners