import React from 'react'
import { SectionContainer } from '../../pages/HomePage'
import { Box, Grid, Typography } from '@mui/material'
import { companiesLogo } from '../../constants/TextContent'
import variables from '../../settings/_variables.scss'

const CompaniesNetwork = props => {
    return <SectionContainer maxWidth='1520px' disableGutters>
        <Box sx={{maxWidth:1520, m: 'auto'}}>
            <Box sx={{  mb: {
                xs: 4, md: 6,
                xl: 8
            }}}>
                <Typography variant='h1' className='extrabold' sx={{textAlign:'center'}}>
                Unternehmen in unserem Netzwerk
                </Typography>
            </Box>
            <Box>
                <Grid container spacing={{xs: 2, sm: 4, md: 3}}>
                    {
                        companiesLogo.map((elem, index) => {
                            return <Grid item xs={4} sm={3} md={3} lg={2} xl={2}>
                                <Box component='img' src={elem.src} key={index} alt={elem.src} sx={{ width: '100%', border:`2px solid ${variables.CYAN}`, borderRadius: '30px', background: variables.WHITE}}/>
                            </Grid>
                        })
                    }
                </Grid>
            </Box>
        </Box>
    </SectionContainer>
}

export default CompaniesNetwork