
import {
    Box,
    Container,
    Link,
    Grid,
    Typography
} from '@mui/material'
import LOGO_WITH_TEXT from '../assets/icons/LOGO_WITH_TEXT.svg'
import { Link as RouterLink } from 'react-router-dom'
import PageMetaTags from '../base/PageMetaTags'
import { useSelector } from 'react-redux'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { HomePageButtons } from './HomePage'
import ArrowLeft from '../assets/icons/ARROW_LEFT.svg'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;



const PdfPage = props => {
    const {pageName, pdfUrl, buttonTitle, buttonLink, isDownloadLink} = props
    const {t} = useTranslation()
    const { responsiveBreakpoint } = useSelector(state => state.initial)

    // const pdfUrl = "https://buntonwebappstorage.blob.core.windows.net/documents/BUN_onepager_2024_Apr.pdf"
    return (
        <Container sx={{py:4, height: '100%'}}>
            <PageMetaTags title={`${pageName} | Bunton`} />
            <Box maxWidth='xl' sx={{m: 'auto'}}>
                <Box sx={{textAlign: 'center'}} >
                    <RouterLink to='/'>
                        <Box
                            component='img'
                            src={LOGO_WITH_TEXT}
                            sx={
                                responsiveBreakpoint === 'mob'
                                    ? {
                                            width: '150px'
                                        }
                                    : {
                                        width: '240px',
                                        height: '65px'
                                    }
                            }
                        />
                    </RouterLink>
                </Box>
                <Grid container sx={{display: 'flex', my: 2}} alignItems='center' spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                        <Typography
                            variant='body2'
                            sx={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                position: 'relative',
                                textAlign: {
                                    xs: 'center',
                                    sm: 'left'
                                },
                                cursor: 'pointer'
                            }}
                        >
                            <RouterLink to='/'><Box component='img' src={ArrowLeft} sx={{mr:1}}/> Go to Bunton website</RouterLink>
                        </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} sx={{textAlign: 'center'}}>
                        <HomePageButtons
                            label={t(buttonTitle)}
                            component={Link}
                            href={buttonLink}
                            download={isDownloadLink}
                            target="_blank"
                            color='primary'
                            variant='contained'
                            size='large'
                            sx={{cursor: 'pointer', ...props.buttonStyle}}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ m: 'auto', py: {xs: 5, md: 10}, maxWidth: '1080px', maxHeight: '1080px'}} height={"100%"} width="fit-content">
                    <Document file={pdfUrl}>
                        <Page pageNumber={1}  renderTextLayer={false} renderAnnotationLayer={false} loading={<Loader />} scale={2}/>
                    </Document>

                </Box>
            </Box>
        </Container>
    )
}

export default PdfPage
