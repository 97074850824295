import React, { useState } from 'react'
import BaseModal from '../../template/BaseModal'
import { Box, FormHelperText, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getYearListArray } from '../../utils/utilities'
import moment from 'moment'
import { ErrorMessage, FormLabels, keys, Placeholder, TextStrings } from '../../constants'
import { MANAGE_EDUCATION_MUTATION } from '../../gql/queries'
import { useMutation } from '@apollo/client'
import {
    addProfileDetailFailure,
    addProfileDetailSuccess,
    editProfileDetailSuccess
} from '../../redux/actions/profileDetail'
import { useForm } from 'react-hook-form'
import CustomFormLabel from '../elements/CustomFormLabel'
import ControlledTextInput from '../molecules/ControlledTextInput'
import Button from '../Button'
import ControlledSelectBox from '../elements/ControlledSelectBox'
import ModalStatusSection from '../elements/ModalStatusSection'
import SuccessIcon from '../../assets/icons/success_green.svg'
import variables from '../../settings/_variables.scss'

const ManageEducationModal = props => {
    const { open, onClose, detailId } = props
    const dispatch = useDispatch()
    const { profileDetail } = useSelector(state => state.profileDetail)
    const yearList = getYearListArray(moment().year(), 1980)
    const [apiError, setApiError] = useState('')
    const [showThankyouMessage, setShowThankyouMessage] = useState(false)
    let certificateDegreeName = ''
    let major = ''
    let yearOfCompletion = ''
    let editableDeatilId = ''

    let editableForm = false

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isDirty, isValid }
    } = useForm({
        defaultValues: {
            certificateDegreeName: certificateDegreeName,
            major: major,
            yearOfCompletion: yearOfCompletion
        }
    })


    if (profileDetail[keys.education] && profileDetail[keys.education].length > 0 && detailId) {
        let formDetailData = profileDetail[keys.education].find(o => o.id == detailId)
        if (formDetailData && Object.keys(formDetailData).length > 0) {
            editableForm = true
            certificateDegreeName = formDetailData.certificateDegreeName
            major = formDetailData.major
            yearOfCompletion = formDetailData.yearOfCompletion
            editableDeatilId = formDetailData.id
        }
    }

    const [manageEducationMutation, { data, error, loading }] = useMutation(
        MANAGE_EDUCATION_MUTATION,
        {
            onCompleted(data) {
                let formData = []
                formData['key'] = keys.education
                formData['data'] = data.manageEducation.educationDetails
                if (data.manageEducation.actionType === 'update') {
                    dispatch(editProfileDetailSuccess(formData))
                } else {
                    dispatch(addProfileDetailSuccess(formData))
                }
                setShowThankyouMessage(true)
                setTimeout(() => {
                    setShowThankyouMessage(false)
                    onClose()
                }, 3000)
            },
            onError(error) {
                setApiError(error)
                dispatch(addProfileDetailFailure(error.message))
            }
        }
    )

    const onSubmit = data => {
        setApiError('')
        let formData = []
        formData['key'] = keys.education
        if (editableForm) {
            formData['data'] = {
                id: editableDeatilId,
                certificateDegreeName: data.certificateDegreeName,
                major: data.major,
                yearOfCompletion: `${data.yearOfCompletion}`,
                actionType: 'update'
            }
        } else {
            formData['data'] = {
                certificateDegreeName: data.certificateDegreeName,
                major: data.major,
                yearOfCompletion: `${data.yearOfCompletion}`,
                actionType: 'create'
            }
        }
        manageEducationMutation({
            variables: {
                ...formData.data
            }
        })
    }

    

    return (
        <BaseModal
            open={open}
            onClose={() =>{ 
                onClose()
                reset()
            }}
            modalTitle={
                <Typography variant='h3' className='small-size-18px'>
                    {`${
                        editableForm ? TextStrings.edit : TextStrings.add
                    } ${TextStrings.education.toLowerCase()}`}
                </Typography>
            }
            boxSx={{
                minHeight: 'auto',
                width: {
                    xs: '90%',
                    sm: '80%',
                    md: '70%',
                    lg: '60%'
                }
            }}
        >
            <Box>
                {showThankyouMessage ? (
                    <ModalStatusSection
                        statusIcon={SuccessIcon}
                        statusText={`Education ${editableForm ? 'Updated' : 'Added'} Successfully.`}
                    />
                ) : (
                    <Box
                        sx={{
                            m: '0 auto'
                        }}
                    >
                        <Box>
                            <CustomFormLabel
                                formLabel={FormLabels.certificateDegreeName}
                                formFieldError={
                                    errors &&
                                    errors.certificateDegreeName &&
                                    errors.certificateDegreeName.message
                                }
                                labelSx={{ mt: 3 }}
                            />
                            <ControlledTextInput
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ErrorMessage.emptyCertificateDegreeName
                                    }
                                }}
                                name='certificateDegreeName'
                                type='text'
                                placeholder={Placeholder.certificateDegreeName}
                                errors={
                                    errors &&
                                    errors.certificateDegreeName &&
                                    errors.certificateDegreeName.message
                                }
                                required={true}
                                fullWidth={true}
                                formContolSx={{ width: '100%' }}
                            />
                        </Box>
                        <Box>
                            <CustomFormLabel
                                formLabel={FormLabels.major}
                                formFieldError={errors && errors.major && errors.major.message}
                                labelSx={{ mt: 3 }}
                            />
                            <ControlledTextInput
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ErrorMessage.emptyMajor
                                    }
                                }}
                                name='major'
                                type='text'
                                placeholder={Placeholder.major}
                                errors={errors && errors.major && errors.major.message}
                                required={true}
                                fullWidth={true}
                                formContolSx={{ width: '100%' }}
                            />
                        </Box>
                        <Box>
                            <Box sx={{ flex: 1 }}>
                                <Box
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '50%'
                                        }
                                    }}
                                >
                                    <CustomFormLabel
                                        formLabel={FormLabels.yearOfCompletion}
                                        formFieldError={
                                            errors &&
                                            errors.yearOfCompletion &&
                                            errors.yearOfCompletion.message
                                        }
                                        labelSx={{ mt: 3 }}
                                    />
                                    <ControlledSelectBox
                                        control={control}
                                        placeholder='Year of completion'
                                        lab='Year of completion'
                                        formControllerSx={{
                                            width: '100%'
                                        }}
                                        options={yearList}
                                        name='yearOfCompletion'
                                        errors={errors && errors.major && errors.major.message}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ErrorMessage.emptyYearOfCompletion
                                            }
                                        }}
                                    />
                                </Box>
                                {apiError && apiError?.message ? (
                                    <FormHelperText id='my-helper-text' error={true}>
                                        <strong>{apiError?.message}</strong>
                                    </FormHelperText>
                                ) : null}
                            </Box>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Button
                                label={editableForm ? TextStrings.saveChanges : TextStrings.save}
                                color='primary'
                                variant='contained'
                                size='large'
                                // disabled={!isDirty || !isValid}
                                onClick={handleSubmit(onSubmit)}
                                type='submit'
                                sx={{
                                    alignSelf: 'center',
                                    mt: 3,
                                    px: '30px',
                                    fontFamily: variables.poppinsBlack
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </BaseModal>
    )
}

export default ManageEducationModal
