import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import InformaticBox from '../elements/InformaticBox'
import BuntonIcon1 from '../../assets/icons/BUN_ICON_1.svg'
import BuntonIcon2 from '../../assets/icons/BUN_ICON_2.svg'
import BuntonIcon3 from '../../assets/icons/BUN_ICON_3.svg'

const newCardData = [
    {
        icon: BuntonIcon1,
        buttonTitle: 'Jetzt registrieren',
        buttonGaDetails: {
            category: 'Executive Matching',
            action: 'Click',
            label: 'Executive Matching'
        },
        frontHeading: 'Vorstandspositionen finden Sie',
        frontSubHeading: 'Ausschreibungen sind transparent & Unternehmen kontaktieren Sie direkt.',
        backHeading: 'Executive Matching',
        backDetails: (
            <Box
                component='ul'
                sx={{ margin: 0, mt: { xs: 3, sm: 2, md: 2 }, paddingInlineStart: '16px' }}
            >
                <Box component='li' sx={{ mt: 1 }}>
                    <Typography variant='paragraph' className='para-font-15px poppins-light'>
                    KI für transparentes und unbiased Matching
                    </Typography>
                </Box>
                <Box component='li' sx={{ mt: 1 }}>
                    <Typography variant='paragraph' className='para-font-15px poppins-light'>
                    Direkter Zugang zu Unternehmen
                    </Typography>
                </Box>
                <Box component='li' sx={{ mt: 1 }}>
                    <Typography variant='paragraph' className='para-font-15px poppins-light'>
                    Vertrauensvoller Umgang mit Profildaten durch Anonymisierung
                    </Typography>
                </Box>
            </Box>
        )
    },
    {
        icon: BuntonIcon2,
        buttonTitle: 'Vorstandspositionen finden',
        buttonGaDetails: {
            category: 'board positions',
            action: 'Click',
            label: 'board positions'
        },
        frontHeading: 'Ihre Stärken zählen',
        frontSubHeading: 'Individuelle Fähigkeiten & Erfahrungen machen den Unterschied.',
        backHeading: 'Recruiting auf Augenhöhe',
        backDetails: (
            <Box
                component='ul'
                sx={{ margin: 0, mt: { xs: 3, sm: 2, md: 2 }, paddingInlineStart: '16px' }}
            >
                <Box component='li' sx={{ mt: 1 }}>
                    <Typography variant='paragraph' className='para-font-15px poppins-light'>
                        Individuelle und proaktive Ansprache zu passgenauen Jobs
                    </Typography>
                </Box>
                <Box component='li' sx={{ mt: 1 }}>
                    <Typography variant='paragraph' className='para-font-15px poppins-light'>
                        Wertschätzende und vertrauensvolle Interaktion
                    </Typography>
                </Box>
                <Box component='li' sx={{ mt: 1 }}>
                    <Typography variant='paragraph' className='para-font-15px poppins-light'>
                        Skillbasiertes und kontinuierliches Profil-Screening
                    </Typography>
                </Box>
            </Box>
        )
    },
    {
        icon: BuntonIcon3,
        buttonTitle: 'Jetzt Profil anlegen',
        buttonGaDetails: {
            category: 'profile now',
            action: 'Click',
            label: 'profile now'
        },
        frontHeading: 'Kommunikation auf Sie abgestimmt',
        frontSubHeading: 'Proaktive Ansprache und spezifische Profilentwicklung für ihren Erfolg.',
        backHeading: 'Profilentwicklung',
        backDetails: (
            <Box
                component='ul'
                sx={{ margin: 0, mt: { xs: 3, sm: 2, md: 2 }, paddingInlineStart: '16px' }}
            >
                <Box component='li' sx={{ mt: 1 }}>
                    <Typography variant='paragraph' className='para-font-15px poppins-light'>
                    Datenbasierte Empfehlungen für Ihre Karriereentwicklung
                    </Typography>
                </Box>
                <Box component='li' sx={{ mt: 1 }}>
                    <Typography variant='paragraph' className='para-font-15px poppins-light'>
                    Exklusiver Austausch mit erfolgreichen Vorständinnen
                    </Typography>
                </Box>
                <Box component='li' sx={{ mt: 1 }}>
                    <Typography variant='paragraph' className='para-font-15px poppins-light'>
                    Netzwerk von erfahrenen Karriere-Coaches
                    </Typography>
                </Box>
            </Box>
        )
    }
]

const InformativeBoxSection = props => {
    return (
        <Container maxWidth='1520px' sx={{ display: 'flex', justifyContent: 'center' }} >
            <Grid
                container
                columnSpacing={{ xs: 0, sm: 2, md: 2, lg: 4 }}
                rowSpacing={{ xs: 4, md: 0 }}
                alignItems='center'
                justifyContent='center'
                sx={{ maxWidth: '1520px' }}
            >
                {newCardData.map((elem, index) => {
                    return (
                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{textAlign:'center'}}>
                            <InformaticBox cardDetails={elem}/>
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    )
}

export default InformativeBoxSection
