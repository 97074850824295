import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import { Box, Typography, Link as MuiLink } from '@mui/material'
import { Link } from 'react-router-dom'
import FooterLogo from '../../assets/icons/LOGO_WITH_TEXT.svg'
import { BuntonContactEmail } from '../../constants/TextContent'
import ShareModal from './ShareModal'
import ThankyouModal from './ThankyouModal'
import { useSelector } from 'react-redux'
import LinkedInFooter from '../../assets/images/linkedin-footer.svg'
import variables from '../../settings/_variables.scss'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { useTranslation } from 'react-i18next'
import ShareJobButton from '../elements/ShareJobButton'

const linkTypographyStyle = {
    textDecoration: 'underline',
    mt: { xs: 1, sm: 0, lg: 1, xl: 0 },
    mr: {
        xs: '0',
        sm: 3,
        md: 3,
        lg: 0,
        xl: 3
    }
}

const Footer = () => {
    const { t } = useTranslation()
    const { isUserAuthenticated } = useSelector(state => state.login)
    const [showShareModal, setShowShareModal] = useState(false)
    const [thanksModal, setThanksModal] = useState(false)

    return (
        <Grid
            container
            rowSpacing={{ xs: 2, lg: 0 }}
            columnSpacing={{ xs: 0, lg: 2 }}
            sx={{
                backgroundColor: variables.CYAN,
                py: 4,
                px: {
                    xs: 5,
                    md: 5,
                    xl: 10
                }
            }}
            alignItems='center'
        >
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={3}
                sx={{
                    textAlign: {
                        xs: 'center',
                        lg: 'left'
                    }
                }}
            >
                <Link to='/'>
                    <Box component='img' src={FooterLogo} sx={{ width: '177px' }} />
                </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={6} sx={{ textAlign: 'center' }}>
                <Box
                    sx={{
                        m: '0 auto',
                        display: {
                            xs: 'block',
                            sm: 'inline-flex',
                            md: 'inline-flex',
                            lg: 'block',
                            xl: 'inline-flex'
                        }
                    }}
                >
                    <Typography sx={linkTypographyStyle}>
                        <Link to='/imprint'>{t('footerlinks.imprints')}</Link>
                    </Typography>
                    <Typography sx={linkTypographyStyle}>
                        <Link to='/terms-and-conditions'>
                            {t('footerlinks.termsandconditions')}
                        </Link>
                    </Typography>

                    <Typography sx={linkTypographyStyle}>
                        <Link to='/privacy-policy'>{t('footerlinks.privacypolicy')}</Link>
                    </Typography>
                    <Typography sx={linkTypographyStyle}>
                        <Link to='/contact-us'>{t('footerlinks.contactus')}</Link>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {isUserAuthenticated ? (
                        <Box>
                            <ShareJobButton
                                color='primary'
                                variant='contained'
                                iconEnable={true}
                                textEnable={false}
                                disableRipple={true}
                                onClick={() => {
                                    setShowShareModal(true)
                                }}
                                sx={{
                                    border: '1px solid #fff',
                                    width: {
                                        xs: '41px',
                                        md: '47px'
                                    },
                                    height: {
                                        xs: '41px',
                                        md: '47px'
                                    },
                                    minWidth: {
                                        xs: '41px',
                                        md: '47px'
                                    },
                                    p: '11px'
                                }}
                                iconStyle={{
                                    ml: 0,
                                    height: '25px',
                                    width: '25px'
                                }}
                            />
                            <ShareModal
                                open={showShareModal}
                                handleClose={() => setShowShareModal(false)}
                                shareJob={false}
                                showThankYou={() => {
                                    setThanksModal(true)
                                    setTimeout(() => {
                                        setThanksModal(false)
                                    }, 3000)
                                }}
                            />
                            <ThankyouModal
                                message='Thank you for sharing!'
                                open={thanksModal}
                                handleClose={() => setThanksModal(false)}
                            />
                        </Box>
                    ) : null}
                    <Box sx={{ mx: '10px' }}>
                        <Box
                            component='a'
                            href='https://www.linkedin.com/company/bunton-gmbh'
                            rel='no-reffer'
                            target='blank'
                        >
                            <Box
                                component='img'
                                src={LinkedInFooter}
                                sx={{
                                    height: {
                                        xs: '41px',
                                        md: '47px'
                                    },
                                    background: '#fff',
                                    borderRadius: '100px'
                                }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{
                            fontWeight: 600,
                            color: variables.BLUE,
                            width: 'fit-content',
                            background: variables.WHITE,
                            height: 'fit-content',
                            borderRadius: '50px',
                            padding: {
                                xs: 1,
                                md: '12px 16px'
                            }
                    }}>
                        <MailOutlineIcon
                            sx={{ mr: 1, verticalAlign: 'middle', width: '20px', height: '20px' }}
                        />
                        <Typography
                            variant='paragraph'
                            sx={{ fontSize: '12px', fontWeight: 'bold' }}
                        >
                            {BuntonContactEmail}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Footer
