import {
    Backdrop,
    Box,
    Container,
    Dialog,
    DialogContent,
    Grid,
    Typography,
    styled
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import variables from '../settings/_variables.scss'
import PageMetaTags from '../base/PageMetaTags'
import Footer from '../components/organisams/footer'
import BuntonHomeVideoWebm from '../assets/videos/BUN_video_Hero.webm'
import BuntonTutorialVideoWebm from '../assets/videos/bun_setup_tutorial.webm'
import BuntonTutorialVideoMp4 from '../assets/videos/bun_setup_tutorial.mp4'
import AnonymousIcon from '../assets/images/anonymous.png'
import EasyIcon from '../assets/images/easy.png'
import SafeIcon from '../assets/images/safe.png'
import { featuredOnLinksAndLogos, partnersLogos } from '../constants/TextContent'
import IntrestingJobs from '../components/IntrestingJobs'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../components'
import BuntonLogoWhite from '../assets/images/BUNTON__Logo_white.svg'
import SurveyIconBlue from '../assets/icons/BUN_Survey_Illustration.svg'
import SurveyIconWhite from '../assets/icons/BUN_Survey_Illustration_2.svg'
import Icons from '../components/Icons'
import WatchIcon from '../assets/icons/watch-video.svg'
import TutorialImage from '../assets/images/BUN_home_tutorial.png'
import { Trans, useTranslation } from 'react-i18next'

import LanguageSwitcher from '../components/molecules/LanguageSwitcher'
import MobHeaderBg from '../assets/images/BUN_MOBILE_HERO.webp'
import MobileMenu from '../template/Navigation/MobileMenu'
import ResponsiveAppBar from '../components/organisams/ResponsiveAppBar'
import { surveyLink } from '../constants/constants'
import JoinBuntonSection from '../components/organisams/JoinBuntonSection'
import HomePageHeaderButtons from '../components/organisams/HomePageHeaderButtons'
import { useSelector } from 'react-redux'
import FounderSection from '../components/organisams/FounderSection'
import OurPartners from '../components/organisams/OurPartners'
import FeaturedOn from '../components/organisams/FeaturedOn'

export const SectionContainer = styled(Container)`
    margin-top: 100px;
    padding-left: 200px;
    padding-right: 200px;

    ${props => props.theme.breakpoints.down('xl')} {
        margin-top: 70px;
        padding-left: 100px;
        padding-right: 100px;
    }
    ${props => props.theme.breakpoints.down('lg')} {
        margin-top: 50px;
        padding-left: 75px;
        padding-right: 75px;
    }
    ${props => props.theme.breakpoints.down('md')} {
        padding-left: 50px;
        padding-right: 50px;
    }
    ${props => props.theme.breakpoints.down('sm')} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

export const HomePageButtons = styled(Button)`
    height: fit-content;
    padding: 12px 20px;
    margin-right: 10px;
    font-size: 18px;
    letter-spacing: 1.4px;
    text-align: center;
    ${props => props.theme.breakpoints.down('xl')} {
        font-size: 16px;
    }
    ${props => props.theme.breakpoints.down('md')} {
        font-size: 16px;
    }
    ${props => props.theme.breakpoints.down('sm')} {
        padding: 10px 20px;
    }
`

const HomePage = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const [displayMenu, setDisplayMenu] = useState('none')
    const [showTutorial, setShowTutorial] = useState(false)
    const handleScroll = e => {
        const elem = document.getElementById('main-home-page')
        if (elem) {
            const currVh = elem.scrollTop
            if (currVh > 200) {
                setDisplayMenu('block')
            } else {
                setDisplayMenu('none')
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true)
        return () => {
            window.removeEventListener('scroll', handleScroll, true)
        }
    }, [])
    return (
        <Container
            disableGutters
            maxWidth={false}
            id='main-home-page'
            sx={{
                backgroundColor: 'rgba(255, 250, 244, 1)',
                overflowX: 'hidden',
                height: 'inherit'
            }}
        >
            <PageMetaTags title='Home | Bunton' />
            <SectionContainer
                id='home-page-vide-container'
                disableGutters
                maxWidth={false}
                sx={{
                    maxHeight: '500px',
                    minHeight: {
                        xs: '300px',
                        md: '400px'
                    },
                    height: {
                        xs: '350px',
                        md: '400px',
                        xl: '500px'
                    },
                    position: 'relative',
                    mt: '0 !important',
                    p: '0 !important'
                }}
            >
                {/** Mobile version background image */}
                <Box
                    component='img'
                    src={MobHeaderBg}
                    sx={{
                        position: 'absolute',
                        zIndex: 0,
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        filter: 'brightness(0.9 )',
                        displayPrint: 'none',
                        display: { xs: 'block', md: 'none' }
                    }}
                />

                {/** Web version video background */}
                <Box
                    component='video'
                    preload='auto'
                    autoPlay
                    muted
                    loop
                    sx={{
                        position: 'absolute',
                        zIndex: 0,
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        displayPrint: 'block',
                        display: { xs: 'none', md: 'block' },
                        background: 'rgba(0, 0, 0, 0.5)'
                    }}
                >
                    <source src={BuntonHomeVideoWebm} type='video/webm' />
                    {/* <source src={BuntonHomeVideoMp4} type='video/mp4' /> */}
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0, 0, 0, 0.4)',
                        zIndex: 0
                    }}
                />
                {/** Header menu on video */}
                <Container
                    maxWidth={false}
                    sx={{
                        zIndex: 1,
                        position: 'relative',

                        display: 'block',
                        py: {
                            xs: 1,
                            sm: '20px'
                        },
                        px: {
                            md: '40px',
                            lg: '100px'
                        },
                        height: '20%'
                    }}
                >
                    <Grid container spacing={3} alignItems='center'>
                        <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                            <Box
                                component='img'
                                src={BuntonLogoWhite}
                                sx={{
                                    width: {
                                        xs: '110px',
                                        sm: '150px',
                                        md: '175px'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sx={{ textAlign: 'right' }} xs={8} sm={9} md={9} lg={9} xl={9}>
                            <Box
                                sx={{
                                    display: { xs: 'flex', md: 'none' },
                                    displayPrint: 'none',
                                    float: 'right'
                                }}
                            >
                                <LanguageSwitcher
                                    sx={{ p: '4px', minWidth: 'fit-content', mr: 1 }}
                                    flagSx={{ height: '28px', width: '28px' }}
                                />
                                <MobileMenu
                                    containerSx={{
                                        background: variables.CYAN,
                                        borderRadius: '50px',
                                        height: '36px',
                                        width: '36px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: 'none', md: 'inline-flex' },
                                    displayPrint: 'inline-flex'
                                }}
                            >
                                {
                                    <HomePageButtons
                                        label={
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box
                                                    component='img'
                                                    src={SurveyIconBlue}
                                                    sx={{
                                                        width: '40px',
                                                        height: '29px'
                                                    }}
                                                />
                                                <Typography
                                                    variant='h3'
                                                    className='strong'
                                                    sx={{
                                                        ml: 1,
                                                        fontSize: '16px'
                                                    }}
                                                >
                                                    {t('onlinesurvey')}
                                                </Typography>
                                            </Box>
                                        }
                                        // component='a'
                                        // href={surveyLink}
                                        // target='_blank'
                                        onClick={() => navigate('/online-survey')}
                                        color='secondary'
                                        variant='contained'
                                        size='large'
                                        sx={{
                                            background: variables.WHITE1,
                                            p: '10px 20px',
                                            fontSize: '16px'
                                        }}
                                    />
                                }
                                <HomePageButtons
                                    label={t('login')}
                                    component={Link}
                                    to='/login'
                                    color='secondary'
                                    variant='contained'
                                    size='large'
                                    sx={{
                                        p: '10px 20px',
                                        fontSize: '16px'
                                    }}
                                />
                                <HomePageButtons
                                    label={t('register')}
                                    component={Link}
                                    to='/registration'
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    sx={{
                                        p: '10px 20px',
                                        fontSize: '16px'
                                    }}
                                />
                                <LanguageSwitcher
                                    sx={{
                                        mr: 1,
                                        p: {
                                            xs: '4px',
                                            md: 1
                                        },
                                        minWidth: 'fit-content'
                                    }}
                                    flagSx={{
                                        height: {
                                            xs: '20px',
                                            sm: '28px',
                                            md: '32px'
                                        },
                                        width: {
                                            xs: '20px',
                                            sm: '28px',
                                            md: '32px'
                                        }
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

                {/** Header menu text section */}
                <Box
                    sx={{
                        width: '100%',
                        height: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        verticalAlign: 'middle',
                        textAlign: 'center'
                    }}
                >
                    <Box sx={{ width: '100%' }}>
                        <Box
                            sx={{
                                maxWidth: '1226px',
                                m: 'auto',
                                width: {
                                    sm: '80%',
                                    xs: '90%'
                                },
                                position: 'relative'
                            }}
                        >
                            {responsiveBreakpoint !== 'mob' ? (
                                <Typography variant='h1' className='home-page-heading'>
                                    {' '}
                                    {t('homepageHeading')}
                                </Typography>
                            ) : (
                                <Typography variant='h1' className='home-page-heading'>
                                    <Trans i18nKey='mobileHomepageHeading'>
                                        {t('mobileHomepageHeading')}
                                    </Trans>
                                </Typography>
                            )}
                        </Box>
                        <Box
                            sx={{
                                m: 'auto',
                                position: 'relative',
                                mt: 4,
                                maxWidth: '998px',
                                width: '80%',
                                display: {
                                    xs: 'none',
                                    md: 'block'
                                },
                                displayPrint: 'block'
                            }}
                        >
                            <Typography
                                variant='h2'
                                className='home-page-sub-heading'
                                sx={{ lineHeight: '1.5 !important' }}
                            >
                                {t('homepageSubHeading')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            maxWidth: '1226px',
                            bottom: '-52px',
                            width: '100%',
                            position: 'absolute',
                            displayPrint: 'block'
                        }}
                    >
                        <HomePageHeaderButtons />
                        {/* <JobSearch buttonColor='secondary'/> */}
                    </Box>
                </Box>
            </SectionContainer>

            {/** Navigation section */}
            <Box sx={{ display: displayMenu, height: 0 }}>
                <ResponsiveAppBar />
            </Box>

            {/** Latest jobs sections */}
            <SectionContainer
                maxWidth='xl'
                sx={{
                    mt: {
                        xs: 10,
                        xl: '120px !important'
                    }
                }}
            >
                <IntrestingJobs />
            </SectionContainer>

            {/** Marketing section */}
            <SectionContainer
                maxWidth='xl'
                sx={{
                    textAlign: 'center',
                    displayPrint: 'block',
                    mt: {
                        xs: 4,
                        sm: 6,
                        lg: 9,
                        xl: 12
                    }
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1056px',
                        margin: 'auto',
                        textAlign: {
                            xs: 'left',
                            sm: 'center'
                        }
                    }}
                >
                    <Typography
                        variant='h3'
                        className='small-size-18px font-family-poppins-regular'
                        sx={{ lineHeight: '1.5' }}
                    >
                        <Trans i18nKey='homepageMarketing.maintext'>
                            {t('homepageMarketing.maintext')}
                        </Trans>
                    </Typography>
                </Box>
                <Box sx={{ m: 'auto', mt: '50px' }}>
                    <Grid
                        container
                        columnSpacing={{
                            xs: 2,
                            sm: 3,
                            md: 10,
                            lg: 20
                        }}
                        alignItems='start'
                        justifyContent='center'
                    >
                        <Grid item xs={4} sm={4} md={4}>
                            <Box>
                                <Box>
                                    <Box
                                        component='img'
                                        src={AnonymousIcon}
                                        height={{
                                            xs: '100px',
                                            sm: '150px',
                                            md: '170px'
                                        }}
                                    />
                                </Box>
                                <Box sx={{ my: { xs: 1, sm: 2, md: 3 } }}>
                                    <Typography variant='h2' className=' extrabold'>
                                        {t('homepageMarketing.subsection.anonymous.heading')}
                                    </Typography>
                                </Box>
                                {!['mob', 'tab'].includes(responsiveBreakpoint) && (
                                    <>
                                        <Box>
                                            <Typography
                                                variant='h3'
                                                className='small-size-18px font-family-poppins-regular'
                                                sx
                                            >
                                                {t(
                                                    'homepageMarketing.subsection.anonymous.details'
                                                )}
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Box>
                                <Box>
                                    <Box
                                        component='img'
                                        src={EasyIcon}
                                        height={{
                                            xs: '100px',
                                            sm: '150px',
                                            md: '170px'
                                        }}
                                    />
                                </Box>
                                <Box sx={{ my: { xs: 1, sm: 2, md: 3 } }}>
                                    <Typography variant='h2' className=' extrabold'>
                                        {t('homepageMarketing.subsection.easy.heading')}
                                    </Typography>
                                </Box>
                                {!['mob', 'tab'].includes(responsiveBreakpoint) && (
                                    <>
                                        <Box>
                                            <Typography
                                                variant='h3'
                                                className='small-size-18px font-family-poppins-regular'
                                            >
                                                {t('homepageMarketing.subsection.easy.details')}
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <Box>
                                <Box>
                                    <Box
                                        component='img'
                                        src={SafeIcon}
                                        height={{
                                            xs: '100px',
                                            sm: '150px',
                                            md: '170px'
                                        }}
                                    />
                                </Box>
                                <Box sx={{ my: { xs: 1, sm: 2, md: 3 } }}>
                                    <Typography variant='h2' className='extrabold'>
                                        {t('homepageMarketing.subsection.safe.heading')}
                                    </Typography>
                                </Box>
                                {!['mob', 'tab'].includes(responsiveBreakpoint) && (
                                    <>
                                        <Box>
                                            <Typography
                                                variant='h3'
                                                className='small-size-18px font-family-poppins-regular'
                                            >
                                                {t('homepageMarketing.subsection.safe.details')}
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </SectionContainer>

            <SectionContainer maxWidth='xl'>
                <Grid container columnSpacing={4}>
                    <Grid item md={6} sm={6} xs={12}>
                        <Box>
                            <Box
                                component='img'
                                src={TutorialImage}
                                sx={{
                                    maxWidth: '100%',
                                    width: '100%',
                                    height: {
                                        xs: '170px',
                                        sm: '300px',
                                        md: '400px'
                                    },
                                    objectFit: 'cover',
                                    borderRadius: {
                                        xs: '30px',
                                        md: '50px'
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} textAlign='left'>
                        <Box sx={{ maxWidth: '449px', width: '100%', m: 'auto' }}>
                            <Box sx={{ my: { xs: 3, md: 4 } }}>
                                <Typography variant='h1' className='section-heading extrabold'>
                                    {t('homepageGetstarted.heading')}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant='h3'
                                    className='small-size-18px font-family-poppins-regular'
                                >
                                    {t('homepageGetstarted.details')}
                                </Typography>
                            </Box>
                            <Grid
                                container
                                spacing={{ xs: 1, sm: 2, md: 3 }}
                                justifyContent='center'
                                sx={{ mt: { xs: 2, sm: 3, md: 4 } }}
                            >
                                <Grid item md={6} sm={6} xs={6}>
                                    <Box>
                                        <Dialog
                                            // fullScreen={fullScreen}
                                            open={showTutorial}
                                            onClose={() => setShowTutorial(false)}
                                            aria-labelledby='bunton-registration-tutorial'
                                            PaperProps={{
                                                sx: {
                                                    maxWidth: {
                                                        md: '70%',
                                                        lg: '65%',
                                                        xl: '80%'
                                                    }
                                                }
                                            }}
                                            slots={{ backdrop: Backdrop }}
                                            slotProps={{
                                                backdrop: {
                                                    sx: {
                                                        //Your style here....
                                                        backgroundColor: 'rgba(255, 255, 255, 0.4)'
                                                    }
                                                }
                                            }}
                                        >
                                            <DialogContent sx={{ p: 0 }}>
                                                <Box
                                                    component='video'
                                                    preload='auto'
                                                    autoPlay
                                                    loop
                                                    sx={{ width: '100%', height: '100%' }}
                                                >
                                                    <source
                                                        src={BuntonTutorialVideoWebm}
                                                        type='video/webm'
                                                    />
                                                    <source
                                                        src={BuntonTutorialVideoMp4}
                                                        type='video/mp4'
                                                    />
                                                </Box>
                                            </DialogContent>
                                        </Dialog>
                                    </Box>
                                    <HomePageButtons
                                        label={t('watchtutorialbutton')}
                                        startIcon={
                                            <Icons
                                                src={WatchIcon}
                                                sx={{ height: '30px', width: '30px' }}
                                            />
                                        }
                                        sx={{ width: '100%' }}
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        onClick={() => setShowTutorial(true)}
                                    />
                                </Grid>
                                <Grid item md={6} sm={6} xs={6}>
                                    <HomePageButtons
                                        label={t('register')}
                                        component={Link}
                                        sx={{ width: '100%' }}
                                        to='/registration'
                                        color='secondary'
                                        variant='contained'
                                        size='large'
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </SectionContainer>

            <Box
                sx={{
                    displayPrint: 'block',
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block'
                    }
                }}
            >
                <SectionContainer maxWidth='xl'>
                    <Box>
                        <Typography variant='h1' className='section-heading'>
                            {t('featuredon')}
                        </Typography>
                    </Box>
                </SectionContainer>
                <SectionContainer maxWidth={false} sx={{ background: variables.CYAN, py: 5, mt: '32px !important'}}>
                    <FeaturedOn/>
                </SectionContainer>
            </Box>

            <Grid container direction={{ xs: 'column-reverse', md: 'row' }}>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                    <FounderSection aboutBunton={true} />
                </Grid>
                <Grid item md={12} xs={12} sm={12} lg={12}>
                    <SectionContainer maxWidth='xl'>
                        <Box>
                            <Typography variant='h1' className='section-heading'>
                                {t('ourpartners')}
                            </Typography>
                        </Box>
                        <OurPartners/>
                    </SectionContainer>
                </Grid>
            </Grid>

            <JoinBuntonSection />

            {process.env.REACT_APP_SURVEY_LINK === 'true' && (
                <SectionContainer
                    maxWidth={false}
                    disableGutters
                    sx={{ background: variables.BLUE, mt: 0, p: '40px' }}
                >
                    <Grid
                        container
                        alignItems='center'
                        columnSpacing={{ xs: 0, md: 2 }}
                        rowSpacing={{ xs: 2, md: 0 }}
                        sx={{ m: 'auto', textAlign: 'center' }}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={3} sx={{ textAlign: 'center' }}>
                            <Box sx={{ color: variables.WHITE }}>
                                <Box
                                    component='img'
                                    src={SurveyIconWhite}
                                    sx={{ height: '125px' }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: 'center' }}>
                            <Box>
                                <Typography
                                    variant='h1'
                                    className='pageHeading white-color'
                                    sx={{ lineHeight: '1.5' }}
                                >
                                    <Trans i18nKey='survey.heading'>{t('survey.heading')}</Trans>
                                </Typography>
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <Typography
                                    variant='h3'
                                    className='small-size-18px font-family-poppins-regular'
                                    sx={{ lineHeight: '1.5', color: variables.WHITE }}
                                >
                                    <Trans i18nKey='survey.description'>
                                        {t('survey.description')}
                                    </Trans>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} sx={{ textAlign: 'center' }}>
                            <HomePageButtons
                                label={t('participatenow')}
                                component='a'
                                href={surveyLink}
                                target='_blank'
                                color='secondary'
                                variant='contained'
                                size='large'
                            />
                        </Grid>
                    </Grid>
                </SectionContainer>
            )}
            <Box>
                <Footer />
            </Box>
        </Container>
    )
}

export default HomePage
