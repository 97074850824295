import { createTheme } from '@mui/material'
import { createBreakpoints, fontFamily, fontSize } from '@mui/system'
import variables from './_variables.scss'

const breakpoints = createBreakpoints({
    xs: 357,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536
})

export const theme = createTheme({
    components: {
        MuiAvatar: {
            variants: [
                {
                    props: { variant: 'user-images' },
                    style: {
                        width: '110px',
                        height: '110px',
                        borderRadius: '100px'
                    }
                },
                {
                    props: { variant: 'details-icons' },
                    style: {
                        borderRadius: '0',
                        height: '20px',
                        width: '20px',
                        marginRight: '15px',
                        p: 1
                    }
                },
                {
                    props: { variant: 'action-icons-blue' },
                    style: {
                        borderRadius: '100px',
                        backgroundColor: `${variables.BLUE}`,
                        height: '40px',
                        width: '40px'
                    }
                }
            ]
        },
        MuiBadge: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: variables.NOTIFICATIONCOLOR,
                    minWidth: '16px',
                    height: '16px',
                    top: '15%',
                    right: '5%',
                    [breakpoints.down('sm')]: {
                        right: '7%'
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '40px',
                    padding: '14px',
                    fontSize: '16px',
                    boxShadow: 'none',
                    fontFamily: variables.poppinsBlack,
                    letterSpacing: '0px'
                },
                containedPrimary: {
                    backgroundColor: `${variables.BLUE}`,
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: `${variables.BLUE}`,
                        // letterSpacing: '2px',
                        boxShadow: 'none'
                    }
                },
                containedSecondary: {
                    backgroundColor: `${variables.CYAN}`,
                    color: `${variables.BLUE}`,
                    textTransform: 'none',
                    fontSize: '15px',
                    '&:hover': {
                        backgroundColor: `${variables.CYAN}`,
                        // letterSpacing: '2px',
                        boxShadow: 'none'
                    },
                    '&.strong': {
                        fontWeight: 900
                    }
                },
                endIcon: {
                    marginLeft: '10px'
                },
                startIcon: {
                    marginRight: '14px'
                }
            },
            variants: [
                {
                    props: { variant: 'cookieButtons' },
                    style: {
                        backgroundColor: variables.WHITE,
                        color: variables.BLUE,
                        padding: '15px',
                        fontSize: '15px',
                        borderRadius: '50px',
                        width: '100%',
                        minWidth: '100px',
                        '&:hover': {
                            backgroundColor: variables.WHITE1
                        }
                    }
                },
                {
                    props: { variant: 'inviteButtons' },
                    style: {
                        backgroundColor: variables.WHITE,
                        color: variables.BLUE,
                        padding: '10px 30px',
                        borderRadius: '50px',
                        minWidth: '100px',
                        '&:hover': {
                            backgroundColor: variables.WHITE1
                        }
                    }
                }
            ]
        },
        MuiContainer: {
            variants: [
                {
                    props: { variant: 'table-rows' },
                    style: {
                        minHeight: '118px',
                        backgroundColor: `${variables.CYAN2}`,
                        marginBottom: '16px',
                        borderRadius: '80px',
                        padding: '16px'
                    }
                },
                {
                    props: { variant: 'engagement-question-row' },
                    style: {
                        backgroundColor: `${variables.CYAN2}`,
                        marginBottom: '16px',
                        borderRadius: '80px',
                        padding: '16px'
                    }
                }
            ]
        },
        MuiCircularProgress: {
            variants: [
                {
                    props: { variant: 'table-load-more' },
                    style: {
                        height: '20px',
                        width: '20px',
                        color: `${variables.BLUE}`
                    }
                }
            ]
        },
        MuiChip: {
            variants: [
                {
                    props: { variant: 'admin-header-menu-item' },
                    style: {
                        backgroundColor: `${variables.CYAN1}`,
                        border: 0,
                        padding: '15px 20px',
                        marginTop: '10px',
                        minHeight: '55px',
                        borderRadius: '50px',
                        marginRight: '20px',
                        cursor: 'pointer',
                        textDecoration: 'none'
                    }
                },
                {
                    props: { variant: 'selected-filter' },
                    style: {
                        p: 2,
                        m: '6px 16px 0px 0px',
                        backgroundColor: variables.WHITE1,
                        borderColor: variables.WHITE1,
                        color: variables.BLUE,
                        fontSize: '15px'
                    }
                },
                {
                    props: { variant: 'status-chip' },
                    style: {
                        backgroundColor: `${variables.BLUE}`,
                        border: 0,
                        padding: 1,
                        cursor: 'default',
                        textDecoration: 'none',
                        color: variables.WHITE1,
                        fontFamily: variables.poppinsBlack,
                        // fontStyle: 'italic'
                    }
                },
            ]
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: variables.poppinsRegular
                },
                h5: {
                    fontSize: '35px',
                    color: variables.CYAN,
                    fontWeight: '600',
                    fontFamily: variables.poppinsRegular
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    display: 'none'
                }
            }
        },
        MuiMobileStepper: {
            styleOverrides: {
                root: {
                    backgroundColor: variables.WHITE1
                },
                dots: {
                    margin: '0px auto 60px auto'
                },
                dot: {
                    border: `2px solid ${variables.CYAN}`,
                    backgroundColor: variables.WHITE1,
                    width: '15px',
                    height: '15px'
                },
                dotActive: {
                    backgroundColor: variables.CYAN
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                positionFixed: {
                    height: {
                        xs: '90px',
                        sm: '101px',
                        md: '101px',
                        lg: '101px',
                        xl: '101px'
                    },
                    display: 'grid',
                    boxShadow: 'none',
                    top: '0px',
                    zIndex: 8
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                actions: {
                    marginLeft: 0
                },
                displayedRows: {
                    color: variables.BLUE,
                    fontFamily: variables.poppinsExtraBold,
                    fontSize: '16px'
                },
                toolbar: {
                    display: 'block',
                    textAlign: 'center'
                }
            }
        }
    },
    typography: {
        h1: {
            fontSize: '40px',
            lineHeight: '1.5',
            fontFamily: variables.poppinsBlack,
            '&.strong': {
                fontWeight: '600'
            },

            '&.pageHeading': {
                fontSize: '35px',
                fontWeight: '900',
                color: variables.BLUE,
                fontFamily: variables.poppinsBlack,
                [breakpoints.down('lg')]: {
                    fontSize: '25px',
                    lineHeight: '1.2'
                },
                [breakpoints.down('md')]: {
                    fontSize: '20px',
                    lineHeight: '1.2'
                },
                
            },
            '&.home-page-heading': {
                fontSize: '45px',
                letterSpacing: '0.5px',
                color: variables.WHITE1,
                fontWeight: 'bold',
                fontFamily: variables.poppinsBlack,
                lineHeight: '1.2',
                [breakpoints.down('xl')]: {
                    fontSize: '35px'
                },
                [breakpoints.down('lg')]: {
                    fontSize: '35px'
                },
                [breakpoints.down('sm')]: {
                    wordWrap: 'break-word',
                    fontSize: '20px'
                }
            },
            '&.landing-page-heading': {
                fontSize: '35px',
                letterSpacing: '0.5px',
                fontWeight: 'bold',
                fontFamily: variables.poppinsBlack,
                lineHeight: '1.2',
                [breakpoints.down('xl')]: {
                    fontSize: '25px'
                },
                [breakpoints.down('lg')]: {
                    fontSize: '20px'
                },
                [breakpoints.down('sm')]: {
                    wordWrap: 'break-word',
                    fontSize: '25px'
                }
            },
            '&.candidate-home-page-heading': {
                fontSize: '45px',
                letterSpacing: '0.5px',
                color: variables.WHITE1,
                fontWeight: 'bold',
                fontFamily: variables.poppinsBlack,
                lineHeight: '1.2',
                [breakpoints.down('xl')]: {
                    fontSize: '35px'
                },
                [breakpoints.down('lg')]: {
                    fontSize: '35px'
                },
                [breakpoints.down('sm')]: {
                    wordWrap: 'break-word',
                    fontSize: '20px'
                }
            },
            '&.section-heading': {
                fontSize: '30px',
                letterSpacing: '0.5px',
                lineHeight: '1.2',
                color: variables.BLUE,
                fontFamily: variables.poppinsBlack,
                fontWeight: '900',
                [breakpoints.down('xl')]: {
                    fontSize: '25px'
                },
                [breakpoints.down('lg')]: {
                    fontSize: '20px'
                },
                [breakpoints.down('md')]: {
                    fontSize: '25px'
                },
                [breakpoints.down('sm')]: {
                    fontSize: '20px'
                }
            },
            '&.blue-color': {
                color: variables.BLUE
            },
            '&.white-color': {
                color: variables.WHITE
            },
            '&.cyan-color': {
                color: variables.CYAN
            },
            '&.poppins-light': {
                fontFamily: variables.poppinsLight
            },
            '&.extrabold': {
                fontFamily: variables.poppinsExtraBold
            },
            [breakpoints.down('xl')]: {
                fontSize: '30px'
            },
            [breakpoints.down('lg')]: {
                fontSize: '25px'
            },
            [breakpoints.down('md')]: {
                fontSize: '20px'
            },
            [breakpoints.down('sm')]: {
                fontSize: '20px'
            }
        },
        h2: {
            fontSize: '30px',
            lineHeight: '1.5',
            // fontFamily: variables.poppinsRegular,
            color: variables.BLUE,
            '&.font-size-30px':{
                fontSize: '30px',
            },
            '&.font-size-28px': {
                fontSize: '28px',
                [breakpoints.down('xl')]: {
                    fontSize: '24px'
                },
                [breakpoints.down('lg')]: {
                    fontSize: '20px'
                }
            },
            '&.extrabold': {
                fontFamily: variables.poppinsExtraBold
            },
            '&.modal-heading': {
                fontFamily: variables.poppinsBlack,
                fontSize: '25px',
                fontWeight: 900
            },
            '&.share-buttons-title': {
                fontFamily: variables.poppinsBlack,
                fontSize: '22px',
                fontWeight: 900
            },
            '&.strong': {
                fontWeight: '600'
            },
            '&.home-page-sub-heading': {
                fontSize: '20px',
                color: variables.WHITE1,
                fontFamily: variables.poppinsRegular,
                lineHeight: '1.2',
                [breakpoints.down('xl')]: {
                    fontSize: '18px'
                },
                [breakpoints.down('lg')]: {
                    fontSize: '18px'
                }
            },
            '&.landing-page-sub-heading': {
                fontSize: '20px',
                fontFamily: variables.poppinsRegular,
                lineHeight: '1.2',
                [breakpoints.down('xl')]: {
                    fontSize: '16px'
                },
                [breakpoints.down('lg')]: {
                    fontSize: '16px'
                }
            },
            '&.blue-color': {
                color: variables.BLUE
            },
            '&.cyan-color': {
                color: variables.CYAN
            },
            '&.white-color': {
                color: variables.WHITE
            },
            [breakpoints.down('xl')]: {
                fontSize: '25px'
            },
            [breakpoints.down('lg')]: {
                fontSize: '20px'
            },
            [breakpoints.down('md')]: {
                fontSize: '18px'
            },
            [breakpoints.down('sm')]: {
                fontSize: '16px'
            }
        },
        h3: {
            fontSize: '20px',
            lineHeight: '1.5',
            fontFamily: variables.poppinsBlack,
            color: variables.BLUE,
            '&.extrabold': {
                fontFamily: variables.poppinsExtraBold
            },
            '&.strong': {
                fontWeight: '600'
            },
            '&.section-heading': {
                fontSize: '25px',
                fontWeight: '600'
            },
            '&.small-size-18px': {
                fontSize: '18px'
            },
            '&.small-size-17px': {
                fontSize: '17px'
            },
            '&.font-family-poppins-regular': {
                fontFamily: variables.poppinsRegular
            },
            '&.poppins-light': {
                fontFamily: variables.poppinsLight
            },
            '&.cyan-color': {
                color: variables.CYAN
            },
            '&.green-color': {
                color: variables.GREEN
            },
            '&.info-card-heading' :{
                fontSize:' 20px',
                [breakpoints.down('sm')]: {
                    fontSize: '17px'
                },    
            },
            [breakpoints.down('xl')]: {
                fontSize: '16px'
            },
            [breakpoints.down('lg')]: {
                fontSize: '14px'
            },
            [breakpoints.down('md')]: {
                fontSize: '12px'
            },
            '&.font-color-white': {
                color: variables.WHITE
            },
        },
        h4: {
            color: variables.CYAN,
            lineHeight: '1.5',
            fontWeight: '600',
            '&.table-row-heading': {
                fontSize: '18px',
                color: `${variables.BLUE}`
            }
        },
        h5: {
            lineHeight: '1.5',
            '&.semi-strong': {
                fontWeight: '600'
            },
            '&.small-size': {
                fontSize: '15px',
                color: `${variables.BLUE}`,
                fontWeight: '100',
                [breakpoints.down('lg')]: {
                    fontSize: '12px'
                },

            },
            '&.small-size-14px': {
                fontSize: '14px',
               
            },
            '&.small-size-15px': {
                fontSize: '15px',
               
            },
            '&.small-size-12px': {
                fontSize: '12px', 
            },
            '&.small-size-16px': {
                fontSize: '16px',
                color: `${variables.BLUE}`,
                fontWeight: '100',
                [breakpoints.down('xl')]: {
                    fontSize: '14px'
                }
            },
            '&.small-size-strong': {
                fontSize: '16px',
                color: `${variables.BLUE}`,
                fontWeight: '600'
            },
            '&.home-page-section-heading': {
                marginBottom: 6,
                fontSize: '30px',
                fontWeight: '600',
                color: variables.BLUE
            },
            '&.cyan-font-35px': {
                fontSize: '35px',
                color: variables.CYAN,
                fontWeight: '600'
            },
            '&.extrabold': {
                fontFamily: variables.poppinsExtraBold
            },
            '&.font-color-white': {
                color: variables.WHITE
            },
            '&.blue-color': {
                color: variables.BLUE
            },
            '&.green-color': {
                color: variables.GREEN
            },
            '&.poppins-light': {
                fontFamily: variables.poppinsLight
            },
            '&.font-family-poppins-regular': {
                fontFamily: variables.poppinsRegular
            },
            '&.founder-name-relation': {
                fontFamily: variables.euphoriaScriptRegular,
                fontSize: '43px'
            }
        },
        paragraph: {
            color: variables.BLUE,
            fontFamily: variables.poppinsBlack,
            lineHeight: '1.5',
            '&.para-font-15px-proxima': {
                fontFamily: variables.poppinsBlack,
                fontSize: '15px',
                fontWeight: '400',
                [breakpoints.down('xl')]: {
                    fontSize: '12px'
                }
            },
            '&.responsive-small-size': {
                fontSize: '15px',
                fontWeight: '400',
                [breakpoints.down('xl')]: {
                    fontSize: '12px'
                }
            },
            '&.para-font-15px': {
                fontSize: '15px',
                fontWeight: '400'
            },
            '&.para-font-18px': {
                fontSize: '18px'
            },
            '&.font-family-poppins-regular': {
                fontFamily: variables.poppinsRegular
            },
            '&.para-font-20px': {
                fontSize: '20px'
            },
            '&.strong': {
                fontWeight: 600
            },
            '&.font-color-white': {
                color: variables.WHITE
            },
            '&.extrabold': {
                fontFamily: variables.poppinsExtraBold
            },
            '&.poppins-light': {
                fontFamily: variables.poppinsLight
            },
        },
        subtitle1: {
            fontFamily: variables.poppinsRegular,
            lineHeight: '1.5',
            '&.cyan-heading-40px': {
                fontSize: '40px',
                color: variables.CYAN,
                fontWeight: '600'
            }
        }
    }
})
