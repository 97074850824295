import React from 'react'
import { SectionContainer } from '../../pages/HomePage'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { SliderFooterArrows } from '../../utils/commonFunctions'
import useDrag from '../../utils/useDrag'
import { Box, Typography } from '@mui/material'

const reviews = [
    {
        'review': '„Dank bunton habe ich von einem Vorstandsposten erfahren, der toll zu mir passt und mich direkt beworben. MATCH :-)“',
        'reviewBy': 'Head of Human Resources, Pharmaindustrie'
    },
    {
        'review': '„Ich habe nun deutlich mehr Jobangebote als mein Headhunter mir vermitteln konnte. Die skillbasierte Analyse wirkt tatsächlich.“',
        'reviewBy': 'Fachbereichsleiterin, Finanzdienstleistungsbranche'
    },
    {
        'review': '„In den Gesprächen habe ich gespürt, dass das Unternehmen wirklich eine Frau mit Veränderungswillen gesucht hat.“',
        'reviewBy': 'Chief of Sales, Automobilbranche'
    }
]


const CandidateReviewSection = props => {
    const { dragStart, dragStop, dragMove } = useDrag()
    const handleDrag =
        ({ scrollContainer }) =>
        ev =>
            dragMove(ev, posDiff => {
                if (scrollContainer.current) {
                    scrollContainer.current.scrollLeft += posDiff
                }
            })
    return <SectionContainer maxWidth='1520px' disableGutters sx={{mt: {
        xs: 5,
        lg: 2,
        xl: 12
    }}}>
        <Box sx={{mb: {
            xs: 4, md: 6,
            xl: 8
        }
        }}>
            <Typography variant='h1' className='extrabold' sx={{textAlign:'center'}}>
            Frauen vertrauen uns
            </Typography>
        </Box>
        <Box>
            <ScrollMenu
                // Footer={SliderFooterArrows}
                onMouseDown={() => dragStart}
                onMouseUp={() => dragStop}
                onMouseMove={handleDrag}
            >
                {
                    reviews.map((elem, index) => {
                        return <Box index={index} key={index} sx={{p: 2 ,  mx: 1, width: 350,
                        textAlign:'center'}}>
                            <Typography variant='h3' className='poppins-light'>
                            {elem.review}
                            </Typography>
                            <Typography variant='h3' className='strong' sx={{mt: 3}}>
                                {elem.reviewBy}
                            </Typography>
                        </Box>
                    })
                }
            </ScrollMenu>
            </Box>
    </SectionContainer>
}

export default CandidateReviewSection