import { Box, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

const FoundersNotes = props => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const fontClass = ['mob', 'tab'].includes(responsiveBreakpoint) ? 'para-font-15px': 'para-font-20px'
    return <Box sx={{my: {xs: 4,  md: 8, lg: 8}, mx: {
        xs: 4, md: 'auto'
    }}}>
        <Box sx={{maxWidth: 1143, m: 'auto'}}>
            <Box sx={{textAlign:'center'}}>
                <Box>
                    <Typography variant='paragraph' className={`${fontClass} font-family-poppins-regular`} >
                    Wir haben bunton gegründet, um Frauen gezielt in Führungspositionen zu bringen.
                    </Typography>
                </Box>
                <Box  sx={{mt: {
                        xs: 2, md: 0
                    }}}>
                    <Typography variant='paragraph' className={`${fontClass} font-family-poppins-regular`}>
                    Unsere KI-basierte Karriereplattform bietet weiblichen Führungskräften einen sicheren Raum, um sich Unternehmen vorzustellen, die auf Diversität setzen.
                    </Typography>    
                </Box>
                <Box sx={{mt: {
                        xs: 2, md: 0
                    }}}>
                    <Typography variant='paragraph' className={`${fontClass} font-family-poppins-regular`}  >
                    Mit modernster Technologie und einem starken Netzwerk schließen wir die Lücke in der Repräsentation von Frauen in Leitungsfunktionen und unterstützen Unternehmen bei der Suche nach qualifizierten Kandidatinnen.
                    </Typography>
                </Box>
            </Box>
            <Box sx={{mt: 5, textAlign:'center'}}>
                <Typography variant='h5' className='founder-name-relation blue-color'>
                Janet Winkler
                </Typography>
                <Typography variant='paragraph' className={`${fontClass} font-family-poppins-regular`}>
                Gründerin & Geschäftsführerin, bunton GmbH
                </Typography>
            </Box>
        </Box>
    </Box>
}

export default FoundersNotes