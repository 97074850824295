import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCookieConsentContext } from '@use-cookie-consent/react'
import React from 'react'
import Button from '../Button'
import variables from '../../settings/_variables.scss'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const CookieBanner = () => {
    const { consent, acceptAllCookies, declineAllCookies, acceptCookies } = useCookieConsentContext(
        {
            defaultConsent: false
        }
    )
    const theme = useTheme()
    const mob = useMediaQuery(theme.breakpoints.down('sm'))

    return Object.keys(consent).length > 0 && cookies.get('buntonCheck') === 'true' ? null : (
        <Box
            sx={{
                position: 'fixed',
                zIndex: '9',
                width: '100%',
                maxWidth: '1720px',
                bottom: '-1px',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Grid
                container
                sx={{
                    px: {xs: 2, md: 3},
                    py: 2,
                    width: {
                        xs: '100%',
                        lg: '90%'
                    },
                    background: variables.BLUE,
                    borderTopRightRadius: {
                        xs: 0,
                        sm: '20px'
                    },
                    borderTopLeftRadius: {
                        xs: 0,
                        sm: '20px'
                    }
                }}
                spacing={1}
                alignItems='center'
                justifyContent='center'
            >
                <Grid item md={9} lg={8} xl={9} sm={12} xs={12}>
                    <Box>
                        <Typography variant='paragraph' className='para-font-15px-proxima font-color-white font-family-poppins-regular'>
                        Um Ihnen ein optimales Erlebnis zu bieten, verwenden wir Technologien wie Cookies, um Geräteinformationen zu speichern und/oder darauf zuzugreifen. Wenn Sie diesen Technologien zustimmen, können wir Daten wie das Surfverhalten oder eindeutige IDs auf dieser Website verarbeiten.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={3} lg={4} xl={3} sm={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item md={12} lg={6} sm={12} xs={12}>
                            <Button
                                label='Alle akzeptieren'
                                sx={{ textTransform: 'none',p: '8px 14px', alignItems: 'center', fontSize: '12px' }}
                                onClick={() => {
                                    cookies.set('buntonCheck', true)
                                    acceptAllCookies()
                                }}
                                variant='cookieButtons'
                            />
                        </Grid>
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                            <Button
                                label='nur Notwendige'
                                sx={{ textTransform: 'none',p: '8px 14px', alignItems: 'center', fontSize: '12px' }}
                                onClick={() => {
                                    cookies.set('buntonCheck', true)
                                    acceptCookies({ necessary: true })
                                }}
                                variant='cookieButtons'
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CookieBanner
