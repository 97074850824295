import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import Button from '../Button'
import ReactGA4 from 'react-ga4'
import { useSelector } from 'react-redux'
import variables from '../../settings/_variables.scss'
import InformativeBoxSection from './InformativeBoxSection'

const LandingPageHeader = props => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const { heading, subHeading, buttonText, buttonLink, isVideo } = props
    return (
        <Container
            id='home-page-vide-container'
            maxWidth={false}
            disableGutters
            sx={{
                mt: '0 !important',
                px: {
                    xs: 0,
                    md: '50px',
                    xl: '100px'
                }
            }}
        >
            <Grid
                container
                columnSpacing={{ xs: 0, md: 2 }}
                sx={{ 
                    m: 'auto'
                }}
                alignItems='center'
                flexDirection={{
                    xs: 'column-reverse',
                    md: 'row'
                }}
            >
                <Grid item md={5} lg={4} xl={4} sm={12} xs={12} sx={{ mx: {
                    xs: 2,
                    md: 0
                } }}>
                    <Box
                        sx={{
                            m: 'auto',
                            backdropFilter: 'blur(10px)',
                            background: {
                                xs: 'rgba(255, 250, 244, 0.8)',
                                md: 'transparent'
                            },
                            borderRadius: {
                                xs: '30px',
                                md: 0
                            },
                            p: {xs: 4,
                                md: 1,
                                lg: 2,
                                xl: 4
                            },
                            pb: {xs: 0, md: 4},
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            top: {
                                xs: '-50px',
                                md: 'auto'
                            }
                        }}
                    >
                        <Box>
                            <Typography
                                variant='h1'
                                className='landing-page-heading strong'
                                sx={{ fontFamily: variables.poppinsBlack }}
                            >
                                {heading}
                            </Typography>
                        </Box>
                        <Box sx={{ mt: {xs: 1, md: 2, lg: 1} }}>
                            <Typography
                                variant='h2'
                                className='landing-page-sub-heading'
                                sx={{textTransform:'none'}}
                            >
                                {subHeading}
                            </Typography>
                        </Box>
                        <Box>
                            <Button
                                label={buttonText}
                                color='primary'
                                variant='contained'
                                size='large'
                                component='a'
                                href={buttonLink}
                                target='_blank'
                                sx={{ mt: {xs: 4, sm:2 , lg: 4}, width: '100%', p: {
                                    xs: '10px 30px',
                                    lg: '20px 50px'
                                } }}
                                onClick={() => {
                                    ReactGA4.event({
                                        category: 'Header Registration',
                                        action: 'Click',
                                        label: 'Landing Page Header'
                                    })
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={7} lg={8} xl={8} sm={12} xs={12}>
                    {['mob', 'tab'].includes(responsiveBreakpoint) ? (
                        <Box
                            component='img'
                            src={props.headerImage}
                            sx={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                                maxHeight: 640,
                                displayPrint: 'block',
                                borderBottomLeftRadius: '0',
                                borderBottomRightRadius: '0'
                            }}
                        />
                    ) : !isVideo ? <Box sx={{
                        width: '100%',
                        maxWidth: '1190px',
                        height: '100%',
                        minHeight: {
                            xs: 300,
                            sm: 450,
                            md: 240,
                            lg: 380,
                            xl: 628
                        },
                        maxHeight: {
                            xs: 300,
                            sm: 450,
                            md: 240,
                            lg: 380,
                            xl: 628
                        },
                        displayPrint: 'block',
                        borderBottomLeftRadius: ['mob', 'tab'].includes(responsiveBreakpoint)  ? 0 : '30px',
                        borderBottomRightRadius: ['mob', 'tab'].includes(responsiveBreakpoint)  ? 0 :   '30px'
                }}>
                    <Box
                        component='img'
                        src={props.headerImage}
                        sx={{
                            objectFit: 'contain',
                            width:'100%'
                        }}
                    />
                </Box> :(
                        <Box
                            component='video'
                            preload='auto'
                            autoPlay
                            muted
                            loop
                            sx={{
                                objectFit: 'cover',
                                width: '100%',
                                height: '100%',
                                minHeight: {
                                    xs: 300,
                                    sm: 450,
                                    md: 240,
                                    lg: 380,
                                    xl: 628
                                },
                                maxHeight: {
                                    xs: 300,
                                    sm: 450,
                                    md: 240,
                                    lg: 380,
                                    xl: 628
                                },
                                displayPrint: 'block',
                                borderBottomLeftRadius: '30px',
                                borderBottomRightRadius: '30px'
                            }}
                        >
                            <source src={props.mp4Video} type='video/webm' />
                            <source src={props.webmVideo} type='video/mp4' />
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Box
                sx={{
                    position: 'relative',
                    top: {
                        md: '-20px',
                        lg:'-20px',
                        xl: '-90px'
                    },
                    mt: {
                        xs: 2,
                        md: 0
                    }
                }}
            >
                <InformativeBoxSection />
            </Box>
        </Container>
    )
}

export default LandingPageHeader
