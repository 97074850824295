import { Box, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import variables from '../../settings/_variables.scss'
import { useSelector } from 'react-redux'
import Button from '../Button'
import { Link } from 'react-router-dom'
import ReactGA4 from 'react-ga4'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'

const InformaticBoxStyle = styled(Box)`
    border: 2px solid ${variables.BLUE};
    background: ${variables.WHITE1};
    border-radius: 30px;
    padding: 24px;
    height: 100%;
`

const InformaticBoxBackStyle = styled(Box)`
    border: 2px solid ${variables.WHITE};
    background: ${variables.CYAN};
    border-radius: 30px;
    padding: 24px;
    height: 100%;
`

const InformaticBox = props => {
    const { cardDetails } = props
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const [flipCard, setFlipCard] = useState(false)

    return (
        <>
            {!['mob', 'tab'].includes(responsiveBreakpoint) ? (
                <Box
                    onMouseEnter={() => setFlipCard(true)}
                    onMouseLeave={() => setFlipCard(false)}
                    sx={{
                        textAlign: 'left',
                        minHeight: '320px',
                        height: {
                            xs: '320px',
                            md: '350px',
                            lg: '320px'
                        },
                        maxHeight: '500px'
                    }}
                >
                    {flipCard ? (
                        <InformaticBoxBackStyle sx={{ minHeight: '320px', maxHeight: '500px' }}>
                            <Box sx={{ p: { xs: 0, md: 0, lg: 0, xl: 2 } }}>
                                <Typography variant='h3' className='extrabold font-color-white'>
                                    {cardDetails.backHeading}
                                </Typography>
                                <Box>{cardDetails.backDetails}</Box>
                                <Box sx={{mt: 3}}>
                                    <Button
                                        label={cardDetails.buttonTitle}
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        component={Link}
                                        to='/registration'
                                        width='100%'
                                        sx={{
                                            mt: 2,
                                            width: '100%',
                                            textAlign: 'center',
                                            fontSize: {
                                                xs: '13px',
                                                sm: '12px',
                                                lg: '14px',
                                                xl: '16px'
                                            }
                                        }}
                                        onClick={() => {
                                            ReactGA4.event({ ...cardDetails.buttonGaDetails })
                                        }}
                                    />
                                </Box>
                            </Box>
                        </InformaticBoxBackStyle>
                    ) : (
                        <InformaticBoxStyle sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                                <Box>
                                    <Box
                                        component='img'
                                        src={cardDetails.icon}
                                        alt=''
                                        sx={{ height: '60px' }}
                                    />
                                </Box>
                                <Box sx={{ mt: 1 }}>
                                    <Typography variant='h3' className='extrabold'>
                                        {cardDetails.frontHeading}
                                    </Typography>
                                    <Typography
                                        variant='h5'
                                        className='small-size-16px poppins-light'
                                        sx={{ mt: 1 }}
                                    >
                                        {cardDetails.frontSubHeading}
                                    </Typography>
                                </Box>
                                <Box sx={{ p: 1, textAlign: 'end', mt: 1 }}>
                                    <ArrowForwardOutlinedIcon
                                        sx={{
                                            color: variables.CYAN,
                                            height: '40px',
                                            width: '40px'
                                        }}
                                    />
                                </Box>
                            </Box>
                        </InformaticBoxStyle>
                    )}
                </Box>
            ) : (
                <Box
                    sx={{
                        textAlign: 'left',
                        minHeight: '320px',
                        border: `2px solid ${variables.BLUE}`,
                        background: variables.WHITE1,
                        borderRadius: '30px',
                        padding: `24px`
                    }}
                >
                    <Box sx={{display: 'flex', alignItems:'center'}}>
                        <Box
                            component='img'
                            src={cardDetails.icon}
                            alt=''
                            sx={{ height: {xs: '45px', md: '60px'} }}
                        />
                        <Typography variant='h3' className='info-card-heading extrabold' sx={{ml: 2}}>
                            {cardDetails.frontHeading}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant='h5'
                            className='small-size-16px poppins-light'
                            sx={{ mt: 1 }}
                        >
                            {cardDetails.frontSubHeading}
                        </Typography>
                    </Box>
                    <Box sx={{mt: '20px'}}>
                        <Typography variant='h3' className='extrabold info-card-heading cyan-color'>
                            {cardDetails.backHeading}
                        </Typography>
                        <Box>{cardDetails.backDetails}</Box>
                        <Box>
                            <Button
                                label={cardDetails.buttonTitle}
                                color='primary'
                                variant='contained'
                                size='large'
                                component={Link}
                                to='/registration'
                                width='100%'
                                sx={{
                                    mt: 2,
                                    width: '100%',
                                    textAlign: 'center',
                                    fontSize: {
                                        xs: '13px',
                                        sm: '12px',
                                        lg: '14px',
                                        xl: '16px'
                                    }
                                }}
                                onClick={() => {
                                    ReactGA4.event({ ...cardDetails.buttonGaDetails })
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    )
}

export default InformaticBox
