import { gql, useQuery } from '@apollo/client'
import Layout from '../template/Layout'
import { Box, Container, Grid} from '@mui/material'
import {useNavigate} from 'react-router-dom' 
import Loader from '../components/Loader'
import PageMetaTags from '../base/PageMetaTags'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next';

const GET_IMRINT_DATA_QUERY = gql`
    query PageContents($type: String!) {
        pageContents(type: $type) {
            id
            description
            germanDescription
        }
    }
`

const PrivacyPolicy = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const { loading, error, data, fetchMore, refetch } = useQuery(GET_IMRINT_DATA_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            type: 'privacy-policy'
        },
        onError(error) {}
    })
    const navigate = useNavigate()
    if (loading) return <Loader />
    if (error) return <Box>{error.message}</Box>
    if(data) {
        const {pageContents} = data
        return (
            <Layout>
                <PageMetaTags title='Privacy Policy | Bunton' />
                <Container
                    disableGutters={true}
                    sx={{
                        padding: {
                            xs: '64px 20px',
                            sm: '64px 40px',
                            md: '64px 80px',
                            lg: '64px 80px',
                            xl: '64px 80px'
                        },
                        paddingBottom: '80px',
                        height: '100%'
                    }}
                >
                    <Grid container>
                        <Grid item md={12} sm={12} xs={12} className="privacyPolicyContainer">
                            {
                              currentLanguage === 'de' && pageContents[0].germanDescription
                                ? ReactHtmlParser(pageContents[0].germanDescription)  // Show German if language is 'de' and German description exists
                                : currentLanguage === 'en' && pageContents[0].description
                                ? ReactHtmlParser(pageContents[0].description)  // Show English if language is 'en' and English description exists
                                : currentLanguage === 'de' && !pageContents[0].germanDescription
                                ? ReactHtmlParser(pageContents[0].description)  // If German description is not available, show English
                                : currentLanguage === 'en' && !pageContents[0].description
                                ? ReactHtmlParser(pageContents[0].germanDescription)  // If English description is not available, show German
                                : null // If neither description exists, render nothing
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Layout>
        )
    }
}

export default PrivacyPolicy
