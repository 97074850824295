import {
    Box,
    CircularProgress,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    RadioGroup,
    Tooltip,
    Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import variables from '../../../../settings/_variables.scss'
import { Button, TextInput } from '../../../../components'
import { StyleFormControl } from '../../../../base/commonStyles'
import { companyStrength, companyType } from '../../../../utils/constantData'
import { Controller, useForm } from 'react-hook-form'
import {
    getFormattedObjectForData,
    removeNoPreferenceEntry
} from '../../../../utils/commonFunctions'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorMessage, Regex } from '../../../../constants'
import Loader from '../../../../components/Loader'
import StatusBox from '../../../../components/StatusBox'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {
    GET_COMPANY_DATA_MUTATION,
    MANAGE_COMPANY_MUTATION
} from '../../../../queries/addCompanyQueries'
import ControlledRadioGroup from '../../../../components/molecules/ControlledRadioGroup'
import ControlledCheckboxGroup from '../../../../components/molecules/ControlledCheckboxGroup'
import ControlledTextInput from '../../../../components/molecules/ControlledTextInput'
import WYSIWYGEditor from '../../../../components/WYSIWYGEditor'

import InfoIcon from '../../../../assets/images/info-icon.svg'
import { DirectUpload } from 'activestorage'
import DefaultCompanyLogo from '../../../../components/molecules/DefaultCompanyLogo'
import moment from 'moment'
import CheckBoxChip from '../../../../components/elements/CheckBoxChip'
import CustomFormLabel from '../../../../components/elements/CustomFormLabel'
import { DateDefaultFromat } from '../../../../constants/constants'

const uploadFile = (file, onMutate, data) => {
    // TODO: Make this URL not static
    const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
    const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl, 'company_logo')

    upload.create((error, blob) => {
        if (error) {
            // Handle the error
        } else {
            // console.log(blob.signed_id)
            // return blob.signed_id
            // Add an appropriately-named hidden input to the form with a
            //  value of blob.signed_id so that the blob ids will be
            //  transmitted in the normal upload flow
            onMutate({
                variables: {
                    ...data,
                    companyLogo: blob.signed_id
                }
            })
            // TODO redirect to photo detail page?
        }
    })
}

const AddCompanyComponent = props => {
    let urlParams = useParams()
    const navigate = useNavigate()
    const isEditPage = urlParams.companyId ? true : false
    const { allIndustries, allStates, allBenefits } = useSelector(state => state.initial)
    const [apiError, setApiError] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [companyTypeRadio, setCompanyTypeRadio] = useState('companyUser')
    const [companyData, setCompanyData] = useState([])
    const [successMessage] = useState(
        props.isEmployer
            ? 'Profile Updated Successfully!'
            : urlParams.companyId
            ? 'Company Details Updated Successfully'
            : 'Company Added Successfully'
    )
    const [inputKey] = useState(Math.random().toString(36))
    const hiddenFileInput = React.useRef(null)

    let initialFormState = {
        companyName: '',
        profileImage: '',
        companyEmail: '',
        alternateEmail: '',
        companyWebsite: '',
        description: '',
        location: '',
        industry: '',
        otherIndustry: '',
        otherEmployeeBenefits: '',
        noOfEmployees: '',
        employeeBenefits: '',
        name: '',
        linkedinUrl: ''
    }

    const { loading, data, error } = useQuery(GET_COMPANY_DATA_MUTATION, {
        variables: { id: urlParams.companyId },
        fetchPolicy: 'network-only',
        skip: !isEditPage,
        onCompleted(data) {
            let formData = {
                companyName: data.company.companyName,
                profileImage:
                    data.company.user && data.company.user.profileImage
                        ? { name: data.company.user.profileImage }
                        : '',
                description: data.company.description,
                companyWebsite: data.company.companyWebsite,
                companyEmail: data.company.companyEmail,
                alternateEmail: data.company?.alternateEmail,
                location: Array.prototype.map
                    .call(data.company.states, function (item) {
                        return item.id
                    })
                    .join(','),

                industry: data.company.industries.map(item => {
                    return item.id
                }),
                otherIndustry: data.company.otherIndustry,
                employeeBenefits: data.company.benefits.map(item => {
                    return item.id
                }),
                noOfEmployees: data?.company?.noOfEmployees,
                name: data?.company?.user?.name,
                linkedinUrl: data?.company?.user?.linkedinUrl,
                otherEmployeeBenefits: data.company.otherBenefits
            }
            setCompanyData(data.company)
            setCompanyTypeRadio(data?.company?.companyType)
            reset(formData)
        }
    })
    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
        getValues,
        watch,
        register,
        setFocus
    } = useForm({
        defaultValues: initialFormState
    })
    React.useEffect(() => {
        const firstError = Object.keys(errors).reduce((field, a) => {
            return !!errors[field] ? field : a
        }, null)
        if (firstError) {
            setFocus(firstError)
        }
    }, [errors, setFocus])
    const noOfEmployees = watch('noOfEmployees')
    const location = watch('location')
    const employeeBenefits = watch('employeeBenefits')
    const description = watch('description')

    const [benefitData] = useState(getFormattedObjectForData(allBenefits))
    useEffect(() => {
        if (!isEditPage) {
            reset(initialFormState)
        }
    }, [isEditPage])

    // useEffect(() => {
    //     const listener = event => {
    //         if (event.code === 'Enter' || event.code === 'NumpadEnter') {
    //             handleSubmit(formSubmit)()
    //             event.preventDefault()
    //         }
    //     }
    //     document.addEventListener('keydown', listener)
    //     return () => {
    //         document.removeEventListener('keydown', listener)
    //     }
    // }, [])

    const [manageCompanyMutation, { loading: saveLoading }] = useMutation(MANAGE_COMPANY_MUTATION, {
        onCompleted(data) {
            setIsSaving(false)
            setIsSuccess(true)
            setTimeout(() => {
                setIsSuccess(false)
                reset(initialFormState)
                if (props.isEmployer) {
                    navigate('/my-profile')
                } else {
                    navigate('/admin-portal/companies')
                }
            }, 3000)
        },
        onError(error) {
            // reset(initialFormState)
            setIsSaving(false)
            setApiError(error)
        }
    })

    function handleIndustrySelect(checkedName) {
        const newNames = getValues('industry')?.includes(checkedName)
            ? getValues('industry')?.filter(name => name !== checkedName)
            : [...(getValues('industry') ?? []), checkedName]

        return newNames
    }

    function handleBenefitsSelect(checkedName) {
        if (
            getValues('employeeBenefits').length > 4 &&
            !getValues('employeeBenefits').includes(checkedName)
        ) {
            return getValues('employeeBenefits')
        } else {
            const newNames = getValues('employeeBenefits')?.includes(checkedName)
                ? getValues('employeeBenefits')?.filter(name => name !== checkedName)
                : [...(getValues('employeeBenefits') ?? []), checkedName]

            return newNames
        }
    }

    const formSubmit = async data => {
        setApiError('')
        setIsSaving(true)
        try {
            let newFile = ''
            let formData = {}
            if (urlParams.companyId) {
                formData = {
                    id: urlParams.companyId,
                    companyName: data.companyName,
                    description: data.description,
                    companyWebsite: data.companyWebsite,
                    email: data.companyEmail,
                    alternateEmail: data.alternateEmail,
                    locationIds: data.location,
                    industryIds: data.industry.join(),
                    otherIndustry: data.otherIndustry,
                    benefitIds: data.employeeBenefits.join(),
                    noOfEmployees: data.noOfEmployees,
                    name: data.name,
                    linkedinUrl: data.linkedinUrl,
                    actionType: 'update',
                    companyType: companyTypeRadio,
                    otherBenefits: data.otherEmployeeBenefits
                }
            } else {
                formData = {
                    companyName: data.companyName,
                    description: data.description,
                    companyWebsite: data.companyWebsite,
                    email: data.companyEmail,
                    alternateEmail: data.alternateEmail,
                    locationIds: data.location,
                    industryIds: data.industry.join(),
                    otherIndustry: data.otherIndustry,
                    benefitIds: data.employeeBenefits.join(),
                    noOfEmployees: data.noOfEmployees,
                    name: data.name,
                    linkedinUrl: data.linkedinUrl,
                    actionType: 'create',
                    companyType: companyTypeRadio,
                    otherBenefits: data.otherEmployeeBenefits
                }
            }
            if (data.companyLogo && data.companyLogo.size && data.companyLogo.size > 0) {
                // const previousFile = data.profileImage
                // const filename = previousFile.name
                // let last_dot = filename.lastIndexOf('.')
                // let ext = filename.slice(last_dot + 1)
                // const companyName = data.companyName.trim().replace(/\s+/g, '_')
                // newFile = new File([previousFile], `${companyName}.${ext}`)
                await uploadFile(data.companyLogo, manageCompanyMutation, formData)
            } else {
                manageCompanyMutation({
                    variables: {
                        ...formData
                    }
                })
            }
        } catch (err) {
            setApiError(err)
        }
    }

    return (
        <Container
            maxWidth='988px'
            disableGutters
            sx={{
                padding: {
                    xs: '0px 40px',
                    sm: '0px 80px',
                    md: '0px 146px'
                },
                maxWidth: {
                    xs: '100vw',
                    sm: '100vw',
                    md: '988px'
                }
            }}
        >
            {!props.isEmployer && (
                <Box
                    sx={{
                        mb: 9
                    }}
                >
                    <Typography
                        variant='h2'
                        className='strong'
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        {isEditPage ? 'Edit company on Bunton' : 'Add a new company to Bunton'}
                    </Typography>
                </Box>
            )}
            {(isEditPage && companyData && !companyData.user) || !isEditPage ? (
                <Box>
                    <CustomFormLabel
                        formLabel='Company Type*'
                        // formFieldError={errors && errors.benefits && errors.benefits.message}
                    />
                    <Box>
                        <FormControl>
                            <RadioGroup
                                name='companyType'
                                sx={{ display: 'flex', flexDirection: 'row' }}
                                value={companyTypeRadio}
                                onChange={e => {
                                    // console.log(e.target.value)
                                    setCompanyTypeRadio(e.target.value)
                                }}
                            >
                                {companyType.map((option, index) => {
                                    let val = props.handleById ? option.id : option.value
                                    return (
                                        <CheckBoxChip
                                            key={index}
                                            register={register}
                                            parentName={option.name}
                                            label={option.name}
                                            value={val}
                                            checked={companyTypeRadio === option.value}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Box>
            ) : null}
            <Box>
                <Box {...register('companyName')}>
                    <CustomFormLabel
                        formLabel='Company Name*'
                        formFieldError={errors && errors.companyName && errors.companyName.message}
                    />
                    <ControlledTextInput
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ErrorMessage.emptyCompanyName
                            },
                            pattern: {
                                value: /^[^\-:,\/()äöüß&!”"*;\\][a-zA-Z :\-,\/()äöüß&!"”*;\\]*$/, 
                                message: 'Please enter valid company name'
                            },
                            validate: title => {
                                return title.trim().length > 2 || 'Please enter valid company name'
                            }
                        }}
                        name='companyName'
                        type='text'
                        placeholder='E.g. creative rocket Inc.'
                        errors={errors && errors.companyName && errors.companyName.message}
                        required={true}
                        fullWidth={true}
                        formContolSx={{ width: '100%' }}
                    />
                </Box>
                <Box {...register('conpanyLogo')}>
                    <CustomFormLabel
                        formLabel='Upload company logo'
                        formFieldError={errors && errors.companyLogo && errors.companyLogo.message}
                    />
                    {data && data.company && data.company.companyLogoUrl && (
                        <Box
                            sx={{
                                p: 2,
                                px: 4,
                                border: `1px solid ${variables.BLUE}`,
                                background: variables.CYAN1,
                                borderRadius: '50px',
                                my: 1
                            }}
                        >
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item md={2} sm={3} xs={4}>
                                    <DefaultCompanyLogo
                                        sx={{ border: `2px solid ${variables.BLUE}` }}
                                        imgSrc={data && data.company && data.company.companyLogoUrl}
                                    />
                                </Grid>
                                <Grid item md={10} sm={9} xs={8}>
                                    <Link
                                        href={data && data.company && data.company.companyLogoUrl}
                                        sx={{ textDecoration: 'none' }}
                                        target='_blank'
                                    >
                                        <Typography
                                            variant='paragraph'
                                            className='para-font-20px strong'
                                        >
                                            {data &&
                                                data.company &&
                                                data.company.companyLogoFilename}
                                        </Typography>
                                    </Link>
                                    <Typography>
                                        Uploaded at{' '}
                                        {moment(
                                            data &&
                                                data.company &&
                                                data.company.companyLogoCreatedat
                                        ).format(DateDefaultFromat)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    <Box>
                        <Controller
                            control={control}
                            rules={{
                                // required: {
                                //     value: true,
                                //     message: ErrorMessage.emptyCompanyLogo
                                // },
                                validate: {
                                    lessThan10MB: file => {
                                        return file && file.size
                                            ? file.size < 1048576 || ErrorMessage.logoSizeValidation
                                            : true
                                    },
                                    acceptedFormats: file => {
                                        return file && file.size
                                            ? ['image/jpeg', 'image/png', 'image/jpg'].includes(
                                                  file?.type
                                              ) || ErrorMessage.logoTypeValidation
                                            : true
                                    }
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <StyleFormControl>
                                    <input
                                        type='file'
                                        ref={hiddenFileInput}
                                        onChange={event => {
                                            onChange(event.target.files[0])
                                        }}
                                        style={{ display: 'none' }}
                                        key={inputKey || ''}
                                    />
                                    <TextInput
                                        type='Name'
                                        name='companyLogo'
                                        disabled
                                        placeholder='E.g. creative rocket Inc.'
                                        onChange={onChange}
                                        formFieldError={
                                            errors &&
                                            errors.companyLogo &&
                                            errors.companyLogo.message
                                        }
                                        required={true}
                                        value={value ? value.name : ''}
                                        fullWidth={true}
                                        inputProps={{ readOnly: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={() => {
                                                            hiddenFileInput.current.click()
                                                        }}
                                                        onMouseDown={() => {}}
                                                    >
                                                        <ArrowRightAltIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </StyleFormControl>
                            )}
                            name='companyLogo'
                        />
                    </Box>
                </Box>
                <Box {...register('companyWebsite')}>
                    <CustomFormLabel
                        formLabel='Website link*'
                        formFieldError={
                            errors && errors.companyWebsite && errors.companyWebsite.message
                        }
                    />
                    <ControlledTextInput
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ErrorMessage.emptyWebsiteLink
                            },
                            pattern: {
                                value: Regex.websiteRegex,
                                message: ErrorMessage.validWebsiteLink
                            }
                        }}
                        name='companyWebsite'
                        placeholder='E.g. creative rocket Inc.'
                        type='text'
                        errors={errors && errors.companyWebsite && errors.companyWebsite.message}
                        required={true}
                        fullWidth={true}
                        formContolSx={{ width: '100%' }}
                    />
                </Box>
                <Box>
                    {companyTypeRadio === 'companyUser' && (
                        <Grid container columnSpacing={{ xs: 0, sm: 2 }}>
                            <Grid item md={6} sm={6} xs={12}>
                                <Box {...register('name')}>
                                    <CustomFormLabel
                                        formLabel='Contact person*'
                                        formFieldError={
                                            errors && errors.name && errors.name.message
                                        }
                                    />
                                    <ControlledTextInput
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ErrorMessage.emptyContactPerson
                                            }
                                        }}
                                        name='name'
                                        placeholder='Enter first & last name'
                                        type='text'
                                        errors={errors && errors.name && errors.name.message}
                                        required={true}
                                        fullWidth={true}
                                        formContolSx={{ width: '100%' }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <Box {...register('companyEmail')}>
                                    <CustomFormLabel
                                        formLabel='Company E-mail*'
                                        formFieldError={
                                            errors &&
                                            errors.companyEmail &&
                                            errors.companyEmail.message
                                        }
                                    />
                                    <ControlledTextInput
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ErrorMessage.emptyCompanyEmail
                                            },
                                            pattern: {
                                                value: Regex.emailRegex,
                                                message: ErrorMessage.validEmail
                                            }
                                        }}
                                        name='companyEmail'
                                        placeholder='E.g. contact@company.com'
                                        type='text'
                                        errors={
                                            errors &&
                                            errors.companyEmail &&
                                            errors.companyEmail.message
                                        }
                                        disabled={isEditPage && companyData && companyData.user}
                                        required={true}
                                        fullWidth={true}
                                        formContolSx={{ width: '100%' }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Box>
                <Box>
                    <Grid container columnSpacing={{ xs: 0, sm: 2 }} alignItems='center'>
                        <Grid item md={6} sm={6} xs={12}>
                            <Box {...register('alternateEmail')}>
                                <CustomFormLabel
                                    formLabel={
                                        <>
                                            Alternate E-mail{' '}
                                            <Tooltip
                                                title='If Alternate E-mail is added all the notification E-mails will be send to the detail added.'
                                                arrow
                                            >
                                                <Box component='img' src={InfoIcon} width='20px' />
                                            </Tooltip>
                                        </>
                                    }
                                    formFieldError={
                                        errors &&
                                        errors.alternateEmail &&
                                        errors.alternateEmail.message
                                    }
                                />
                                <Box>
                                    <ControlledTextInput
                                        control={control}
                                        rules={{
                                            pattern: {
                                                value: Regex.emailRegex,
                                                message: ErrorMessage.validEmail
                                            }
                                        }}
                                        name='alternateEmail'
                                        placeholder='E.g. contact@company.com'
                                        type='text'
                                        errors={
                                            errors &&
                                            errors.alternateEmail &&
                                            errors.alternateEmail.message
                                        }
                                        required={false}
                                        fullWidth={true}
                                        formContolSx={{ width: '100%' }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Box {...register('linkedinUrl')}>
                                <CustomFormLabel
                                    formLabel='LinkedIn Profile'
                                    formFieldError={
                                        errors && errors.linkedinUrl && errors.linkedinUrl.message
                                    }
                                />
                                <ControlledTextInput
                                    control={control}
                                    rules={{
                                        pattern: {
                                            value: Regex.linkedInCompanyPageRegex,
                                            message: ErrorMessage.validLinkedInUrl
                                        }
                                    }}
                                    name='linkedinUrl'
                                    type='text'
                                    errors={
                                        errors && errors.linkedinUrl && errors.linkedinUrl.message
                                    }
                                    fullWidth={true}
                                    formContolSx={{ width: '100%' }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box {...register('description')}>
                    <CustomFormLabel
                        formLabel='About the company'
                        formFieldError={
                            errors && errors.jobDescription && errors.jobDescription.message
                        }
                    />
                    <Controller
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                            <WYSIWYGEditor name='description' {...field} register={register} />
                        )}
                        name='description'
                    />
                    {/* {errors && errors.jobDescription && errors.jobDescription.message ? (
                            <FormHelperText id='my-helper-text'>
                                {errors.jobDescription.message}
                            </FormHelperText>
                        ) : null} */}
                    {/* <Controller
                        control={control}
                        rules={
                            {
                                // required: {
                                //     value: true,
                                //     message: ErrorMessage.emptyPassword
                                // },
                                // minLength: {
                                //     value: 6,
                                //     message: ErrorMessage.validPassword
                                // }
                            }
                        }
                        render={({ field: { onChange, value } }) => (
                            <StyleFormControl>
                                <TextInput
                                    type='About'
                                    name='description'
                                    placeholder='E.g. creative rocket Inc.'
                                    onChange={onChange}
                                    formFieldError=''
                                    required={true}
                                    value={value}
                                    fullWidth={true}
                                    inputProps={{
                                        maxLength: 250
                                    }}
                                    InputProps={{
                                        maxLength: 2,
                                        endAdornment: (
                                            <InputAdornment
                                                position='start'
                                                sx={{ marginLeft: '10px' }}
                                            >
                                                <Box sx={{ position: 'relative' }}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'flex-end',
                                                            ml: '15px',
                                                            fontSize: '15px',
                                                            color: variables.BLUE
                                                        }}
                                                    >
                                                        {`${
                                                            description.length
                                                                ? description.length
                                                                : '0'
                                                        }`}
                                                        /250
                                                    </Box>
                                                </Box>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </StyleFormControl>
                        )}
                        name='description'
                    /> */}
                </Box>
                <Box {...register('noOfEmployees')}>
                    <CustomFormLabel
                        formLabel={
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    Employees*
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            ml: '15px'
                                        }}
                                    >
                                        {`${noOfEmployees ? '1' : '0'}`}/1
                                    </Box>
                                </Box>
                            </>
                        }
                        formFieldError={
                            errors && errors.noOfEmployees && errors.noOfEmployees.message
                        }
                    />

                    <Box>
                        <ControlledRadioGroup
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyEmployee
                                }
                            }}
                            register={register}
                            name='noOfEmployees'
                            options={companyStrength}
                            errors={errors && errors.noOfEmployees && errors.noOfEmployees.message}
                        />
                    </Box>
                </Box>
                <Box {...register('location')}>
                    <CustomFormLabel
                        formLabel={
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                Location HQ*
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        ml: '15px'
                                    }}
                                >
                                    {`${location ? '1' : '0'}`}/1
                                </Box>
                            </Box>
                        }
                        formFieldError={errors && errors.location && errors.location.message}
                    />

                    <Box>
                        <ControlledRadioGroup
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyLocation
                                }
                            }}
                            register={register}
                            name='location'
                            options={allStates}
                            errors={errors && errors.location && errors.location.message}
                            handleById={true}
                        />
                    </Box>
                </Box>

                <Box {...register('industry')}>
                    <CustomFormLabel
                        formLabel='Industry*'
                        formFieldError={
                            (errors && errors.industry && errors.industry.message) || (errors && errors.otherIndustry && errors.otherIndustry.message)
                        }
                    />
                    <ControlledCheckboxGroup
                        control={control}
                        register={register}
                        rules={{
                            validate: value => {
                                return value.length > 0 || ErrorMessage.emptyIndustries
                            }
                        }}
                        otherFieldRules={{
                            required: {
                                value: true,
                                message: ErrorMessage.emptyOthers
                            }
                        }}
                        name='industry'
                        options={removeNoPreferenceEntry(allIndustries)}
                        errors={errors && errors.industry && errors.industry.message}
                        otherFieldErrors={
                            errors && errors.otherIndustry && errors.otherIndustry.message
                        }
                        handleMutliSelect={handleIndustrySelect}
                        resetField={() => setValue('otherIndustry')}
                    />
                </Box>

                <Box {...register('employeeBenefits')}>
                    <CustomFormLabel
                        formLabel={
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                Employee benefits*
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        ml: '15px'
                                    }}
                                >
                                    {`${employeeBenefits ? employeeBenefits.length : 0}`}/5
                                </Box>
                            </Box>
                        }
                        formFieldError={
                            errors && ((errors.employeeBenefits && errors.employeeBenefits.message) || (errors.otherEmployeeBenefits &&
                                errors.otherEmployeeBenefits.message))
                        }
                    />
                    
                    <Box>
                        <ControlledCheckboxGroup
                            control={control}
                            register={register}
                            rules={{
                                validate: value => {
                                    return value.length > 0 || ErrorMessage.emptyBenefits
                                }
                            }}
                            otherFieldRules={{
                                required: {
                                    value: true,
                                    message: ErrorMessage.emptyOthers
                                }
                            }}
                            name='employeeBenefits'
                            options={benefitData}
                            errors={
                                errors && errors.employeeBenefits && errors.employeeBenefits.message
                            }
                            otherFieldErrors={
                                errors &&
                                errors.otherEmployeeBenefits &&
                                errors.otherEmployeeBenefits.message
                            }
                            handleMutliSelect={handleBenefitsSelect}
                            resetField={() => setValue('otherEmployeeBenefits')}
                        />
                    </Box>
                </Box>
                {apiError && apiError?.message ? (
                    <FormHelperText 
                        id='my-helper-text'
                        error={true}
                    >
                        <strong>{apiError?.message}</strong>
                    </FormHelperText>
                ) : null}
                <Box
                    sx={{
                        marginTop: '74px',
                        textAlign: 'center',
                        mb: {
                            xs: '20px',
                            sm: '0px',
                            md: '0px',
                            lg: '0px',
                            xl: '0px'
                        }
                    }}
                >
                    <Button
                        label='Cancel'
                        color='secondary'
                        variant='contained'
                        size='large'
                        sx={{
                            padding: '13px 30px',
                            marginRight: '15px',
                            fontSize: '14px',
                            fontWeight: '600'
                        }}
                        onClick={() => {
                            if (props.isEmployer) {
                                navigate('/my-profile')
                            } else {
                                navigate('/admin-portal/companies')
                            }
                        }}
                    />
                    <Button
                        label={
                            isSaving ? (
                                <CircularProgress
                                    size={20}
                                    sx={{
                                        color: variables.BLUE
                                    }}
                                />
                            ) : (
                                'Publish to bunton'
                            )
                        }
                        disabled={isSaving}
                        color='primary'
                        variant='contained'
                        size='large'
                        onClick={handleSubmit(formSubmit)}
                        sx={{
                            padding: '13px 30px',
                            fontSize: '14px',
                            fontWeight: '600'
                        }}
                    />
                </Box>
            </Box>
            {loading && <Loader />}
            {isSuccess ? <StatusBox statusText={successMessage} /> : null}
        </Container>
    )
}

export default AddCompanyComponent
