import React from 'react'
import { SectionContainer } from '../../pages/HomePage'
import { Box, Divider, Stack, Typography } from '@mui/material'
import FeatherIcon from '../../assets/icons/feather.png'
import ConversationIcon from '../../assets/icons/conversation.png'
import EditIcon from '../../assets/icons/edit.png'
import CelebrationIcon from '../../assets/icons/celebration.png'
import variables from '../../settings/_variables.scss'

const BannerRegistrationSection = props => {
    const generalBuntonFlow = [
        {
            icon: FeatherIcon,
            label: '1. Registrieren',
            description: `Registrieren Sie sich, und erhalten Sie Zugang zu unseren offenen Positionen.`
        },
        {
            icon: EditIcon,
            label: '2. Loslegen',
            description:
                'Sehen Sie sich passende aktuelle Stellen an & erhalten direkte Mitteilungen über neue Möglichkeiten.'
        },
        {
            icon: ConversationIcon,
            label: '3. Profil einrichten',
            description: `Wir schlagen Ihnen basierend auf Ihren persönlichen Fähigkeiten passende Positionen vor.`
        },
        {
            icon: EditIcon,
            label: '4. Gespräche führen',
            description: `Sie bestimmen, ob Sie Anfragen von Unternehmen annehmen. Nach beidseitiger Einwilligung verbinden wir Sie miteinander.`
        },
        {
            icon: CelebrationIcon,
            label: '5. Job-Match',
            description: `Starten Sie Ihren neuen Job bei Ihrem neuen Arbeitgeber.`
        }
    ]
    return (
        <SectionContainer maxWidth={false} >
            <Box>
                <Box
                    sx={{
                        mb: {
                            xs: 4,
                            md: 6,
                            xl: 8
                        }
                    }}
                >
                    <Typography variant='h1' className='extrabold' sx={{ textAlign: {xs: 'left', md: 'center'} }}>
                        Ihr Weg in Top Executive Positionen
                    </Typography>
                </Box>
                <Stack
                    direction={{
                        xs: 'column',
                        lg: 'row'
                    }}
                    divider={
                        <Divider
                            variant='middle'
                            sx={{
                                border: `1px solid ${variables.BLUE}`,
                                m: 0,
                                height: {
                                    xs: '10px',
                                    lg: 0
                                },
                                width: {
                                    xs: 0,
                                    lg: '10px'
                                }
                            }}
                        />
                    }
                    sx={{ alignItems: 'center', justifyContent:'center' }}
                >
                    {generalBuntonFlow.map(step => {
                        return (
                            <Box
                                sx={{
                                    height: {
                                        xs: '134px',
                                        lg: '290px',
                                        xl: '219px'
                                    },
                                    width: {
                                        xs: '80%',
                                        md: '400px',
                                        lg: '288px'
                                    },
                                    p: '25px',
                                    border: `2px solid ${variables.CYAN}`,
                                    borderRadius: '30px',
                                    display: 'flex',
                                    alignItems: 'start'
                                }}
                            >
                                <Box>
                                    <Box
                                        sx={{
                                            display: {
                                                xs: 'flex',
                                                lg: 'block'
                                            },
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Box >
                                            <Box
                                                component='img'
                                                src={step.icon}
                                                sx={{
                                                    height: {
                                                        xs: '40px',
                                                        md: '50px'
                                                    },
                                                    width: {
                                                        xs: '40px',
                                                        md: '50px'
                                                    },
                                                    background: variables.BLUE,
                                                    borderRadius: '10px'
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                ml: {
                                                    xs: 2,
                                                    lg: 0
                                                }
                                            }}
                                        >
                                            <Typography
                                                variant='paragraph'
                                                className='para-font-20px extrabold'
                                            >
                                                {step.label}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography variant='paragraph' className='responsive-small-size'>
                                            {step.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}
                </Stack>
            </Box>
        </SectionContainer>
    )
}

export default BannerRegistrationSection
