import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NavigationRouters from '../../template/NavigationRouters'
import { loginCheckAuth, loginFailure } from '../../redux/actions/login'
import { addHasResume, updateProfileDataFromDB } from '../../redux/actions/profileDetail'
import { keys } from '../../constants'
import { useMediaQuery, useTheme } from '@mui/material'
import { clarity } from 'react-microsoft-clarity'

import {
    saveBenefits,
    saveDepartments,
    saveIndustries,
    saveProfessions,
    saveStates,
    responsiveBreakpoint,
    saveJobTypes,
    saveBusinessNetworks
} from '../../redux/actions/initialData'
import Loader from '../Loader'
import CookieBanner from '../elements/CookieBanner'

const currentUser = gql`
    query UserStore {
        currentUser {
            id
            name
            firstName
            lastName
            name
            email
            lastLogoutAt
            userRole
            mobileNumber
            profileImage
            hasAnswered
            company {
                id
                companyLogoUrl
            }
            candidate {
                id
                language
                hasResume
                about
                benefits {
                    id
                    name
                }
            }
        }
        experienceDetails {
            id
            jobTitle
            startDate
            endDate
            departmentId
            companyName
            industryId
            otherDepartment
            otherIndustry
            candidate {
                id
                language
            }
            department {
                id
                name
            }
            industry {
                id
                name
            }
            isCurrentJob
        }
        educationDetails {
            id
            certificateDegreeName
            yearOfCompletion
            major
            instituteName
            startDate
            endDate
        }
        getAllProfessions {
            id
            name
        }
        getAllDepartments {
            id
            name
        }
        getAllIndustries {
            id
            name
        }
        getAllStates {
            id
            name
        }
        benefits {
            id
            name
        }
        jobTypes {
            id
            name
        }
        getAllBusinessNetwork {
            id
            name
        }
    }
`

const CheckAuth = props => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [pageLoading, setPageLoading] = useState(true)
    const web = useMediaQuery(theme.breakpoints.up('md'))
    const tabStart = useMediaQuery(theme.breakpoints.down('md'))
    const tabDown = useMediaQuery(theme.breakpoints.up('sm'))
    const mob = useMediaQuery(theme.breakpoints.down('sm'))
    if (web) {
        dispatch(responsiveBreakpoint('web'))
    } else if (tabStart && tabDown) {
        dispatch(responsiveBreakpoint('tab'))
    } else if (mob) {
        dispatch(responsiveBreakpoint('mob'))
    } else {
        dispatch(responsiveBreakpoint('web'))
    }


    const handleLoading = () => {
        setPageLoading(false)
        }
        
    useEffect(() => {
        window.addEventListener("load",handleLoading, true);
        return () => {
            window.removeEventListener("load",handleLoading, true);
        }
    }, [])


    const { loading, error, data } = useQuery(currentUser, {
        onCompleted(data) {
            if(data.currentUser && data.currentUser.id) {
                clarity.identify(data.currentUser.id, {
                    name: data.currentUser.name,
                    email: data.currentUser.email,
                    userRole: data.currentUser.userRole,
                });
            }
            dispatch(loginCheckAuth(data))
            dispatch(saveProfessions(data))
            dispatch(saveDepartments(data))
            dispatch(saveIndustries(data))
            dispatch(saveStates(data))
            dispatch(saveBenefits(data))
            dispatch(saveJobTypes(data))
            dispatch(saveBusinessNetworks(data))
            // //saving education data in redux
            if(data.educationDetails && data.educationDetails.length > 0) {
                let educationData = []
                educationData['key'] = keys.education
                educationData['data'] = data.educationDetails ? data.educationDetails : []
                dispatch(updateProfileDataFromDB(educationData))
            }

            // //saving experience data in redux
            if(data.experienceDetails && data.experienceDetails.length > 0){
                let experienceData = []
                experienceData['key'] = keys.workExperience
                experienceData['data'] = data.experienceDetails ? data.experienceDetails : []
                dispatch(updateProfileDataFromDB(experienceData))
            }

            // //saving language data in redux
            if (data?.currentUser?.candidate?.language) {
                let languageData = []
                languageData['key'] = keys.languages
                languageData['data'] = [{ language: data?.currentUser?.candidate?.language }]
                dispatch(updateProfileDataFromDB(languageData))
            }
            // //saving avatar data in redux
            if (data?.currentUser?.profileImage) {
                let avatarData = []
                avatarData['key'] = keys.selectAnAvatar
                avatarData['data'] = [{ profileImage: data?.currentUser?.profileImage }]
                dispatch(updateProfileDataFromDB(avatarData))
            }

            // //saving additional indo data in redux

            if (
                data?.currentUser?.candidate?.about ||
                (data?.currentUser?.candidate?.benefits &&
                    data?.currentUser?.candidate?.benefits.length > 0)
            ) {
                let avatarData = []
                avatarData['key'] = keys.additionalInfos
                avatarData['data'] = [
                    {
                        about: data?.currentUser?.candidate?.about,
                        benefits: data?.currentUser?.candidate?.benefits
                    }
                ]

                dispatch(updateProfileDataFromDB(avatarData))
            }

            // saving upload resume flag in redux
            if (data?.currentUser?.candidate?.hasResume) {
                let resume = []
                resume['key'] = keys.uploadResume
                resume['data'] = [{ language: data?.currentUser?.candidate?.hasResume }]
                dispatch(addHasResume(resume))
            }
            // setPageLoading(false)
        },
        onError(error) {
            dispatch(loginFailure(error.message))
        }
    })

    if (error) return `Error! ${error.message}`

    return loading ? <Loader /> :
    <>
        <CookieBanner/>
        <NavigationRouters />
    </>
}

export default CheckAuth
