import React, { useState } from 'react'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useMutation } from '@apollo/client'
import { LIKE_JOB_MUTATION } from '../../queries/jobPostQueries'
import Button from '../Button'
import variables from '../../settings/_variables.scss'
import { Box, Typography } from '@mui/material'
import { abrevatedLikesCount } from '../../utils/commonFunctions'
import moment from 'moment'

const LikeButton = props => {
    const { userId, jobPostId, jobCreateDate, isLiked, totalLikes, role, isUserAuthenticated } = props
    const [isJobLiked, setIsJobLiked] = useState(isLiked)
    const todayDate = moment()
    const dateDiff = todayDate.diff(moment(jobCreateDate), 'days')
    const [likes, setLikes] = useState(
        dateDiff > 0 ? Number(jobPostId.toString().split('').pop()) + 1 + totalLikes : totalLikes 
    )
    const [likeJobMutation] = useMutation(LIKE_JOB_MUTATION, {
        onCompleted(data) {
            setIsJobLiked(true)
            setLikes(likes + 1)
            if (props.refetchAppliedJobCount) {
                props.refetchAppliedJobCount()
            }
        }
    })
    return (
        <Box>
            <Button
                color='secondary'
                variant='contained'
                size='large'
                sx={{
                    width: 'auto',
                    height: '50px',
                    minWidth: '50px',
                    p: 1,

                    background: variables.CYAN,
                    color: variables.BLUE,
                    '&:disabled': {
                        background: variables.CYAN,
                        color: variables.BLUE
                    },
                    ...props.sx
                }}
                label={''}
                disabled={
                    !isUserAuthenticated ||
                    (isUserAuthenticated && (isJobLiked || ['admin', 'employer'].includes(role)))
                }
                onClick={e => {
                    e.preventDefault()
                    if (isUserAuthenticated) {
                        likeJobMutation({
                            variables: {
                                userId: userId,
                                jobId: jobPostId
                            }
                        })
                    }
                }}
            >

                    <Box sx={{ display: 'block', maxHeight:' 34px' }}>
                        <Box>
                            {
                            (isUserAuthenticated &&
                                (isJobLiked || (['admin', 'employer'].includes(role) && likes > 0))) ? (
                                <FavoriteIcon sx={{ fontSize: '18px', color: variables.BLUE }} />
                            ) : (
                                <FavoriteBorderIcon sx={{ fontSize: '18px', color: variables.BLUE, stroke: variables.BLUE,
                                    strokeWidth: 1 }}/>
                            )}
                            <Typography
                                variant='paragraph'
                                className='para-font-15px strong'
                                sx={{display:'block',position: 'relative',
                                    top: '-10px'}}
                            >
                                {abrevatedLikesCount(likes)}
                            </Typography>
                        </Box>
                        
                    </Box>
            </Button>
        </Box>
    )
}

export default LikeButton
