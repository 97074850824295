import { gql, useQuery } from '@apollo/client'
import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
// import {partnersLogos} from '../../constants/TextContent'
import variables from '../../settings/_variables.scss'
import Loader from '../Loader'
import { featuredOnLinksAndLogos, partnersLogos } from '../../constants/TextContent'

const GET_FEATURED_ON_DATA_QUERY = gql`
    query PageImages($type: String!) {
        pageImages(type: $type) {
            id
            imageUrl
            link
        }
    }
`

const FeaturedOn = props => { 
    const { loading, error, data, fetchMore, refetch } = useQuery(GET_FEATURED_ON_DATA_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            type: 'featured_on'
        },
        onError(error) {}
    })

    if (loading) return <Loader />
    if (error) return <Box>{error.message}</Box>

    if(data) {
        const {pageImages} = data
        return    <Box maxWidth='xl' sx={{ m: 'auto' }}>
                    <Box sx={{ width: 'fit-content', margin: 'auto' }}>
                        <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}
                        >
                            {pageImages.map((elem, index) => (
                                <Box
                                    sx={{
                                        mx: {
                                            xs: 1,
                                            sm: 2,
                                            md: 3
                                        },
                                        my: 1
                                    }}
                                    key={index}
                                >
                                    <a href={elem.link} target='_blank' rel='noreferrer'>
                                        <Box
                                            component='img'
                                            src={elem.imageUrl}
                                            alt={'logo'}
                                            sx={{
                                                maxWidth: '272px',
                                                height: {
                                                    xs: '60px',
                                                    sm: '80px',
                                                    md: '100px',
                                                    xl: '126px'
                                                },
                                                ...elem.customSx
                                            }}
                                        />
                                    </a>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
    }
}

export default FeaturedOn