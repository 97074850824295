import * as React from 'react'
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Menu,
    Container,
    Avatar,
    Button as MuiButton,
    MenuItem,
    Grid,
    Tooltip,
    styled
} from '@mui/material'
import BuntonLogo from '../../assets/images/Logo.svg'
import HomeIcon from '../../assets/icons/home.svg'
import UsersIcon from '../../assets/icons/employee.svg'
import JobsIcon from '../../assets/icons/DEPARTMENT.svg'
import CompaniesIcon from '../../assets/icons/COMPANIES.svg'
import BuntonIcon from '../../assets/icons/BUNTON.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import variables from '../../settings/_variables.scss'
import { HomePageButtons } from '../../pages/HomePage'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAvatrURL } from '../../utils/utilities'
import DefaultProfilIcon from '../../assets/images/avatar.png'
import { truncateDetails } from '../../utils/commonFunctions'
import ShareJobButton from '../elements/ShareJobButton'
import ShareModal from './ShareModal'
import { useState } from 'react'
import ThankyouModal from './ThankyouModal'
import LanguageSwitcher from '../molecules/LanguageSwitcher'
import ProfileIcon from '../../assets/icons/profile.svg'
import LogoutIcon from '@mui/icons-material/Logout'
import MobileMenu from '../../template/Navigation/MobileMenu'
import SurveyMenuFlag from '../elements/SurveyMenuFlag'
import SurveyIconBlue from '../../assets/icons/BUN_Survey_Illustration.svg'
import SurveyIconWhite from '../../assets/icons/BUN_Survey_Illustration_2.svg'

import SettingsIcon from '../../assets/icons/settings.svg'

export const NavMenuIcon = styled(Box)`
    width: 20px;
    height: 20px;
`

function ResponsiveAppBar() {
    const { t } = useTranslation()
    const location = useLocation()
    const { isUserAuthenticated, loginCheckAuthDetails, loginDetails } = useSelector(
        state => state.login
    )
    const navigate = useNavigate()
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    const [anchorElUser, setAnchorElUser] = useState(null)
    const [showShareModal, setShowShareModal] = useState(false)
    const [thanksModal, setThanksModal] = useState(false)

    const publicOptions = [
        {
            menuItem: 'Home',
            traslationKey: 'home',
            link: '/',
            menuIcon: <NavMenuIcon component='img' src={HomeIcon} />
        },
        {
            menuItem: 'Jobs',
            traslationKey: 'jobs',
            link: '/job-list',
            menuIcon: <NavMenuIcon component='img' src={JobsIcon} />
        },
        {
            menuItem: 'Companies',
            traslationKey: 'companies',
            link: '/companies',
            menuIcon: <NavMenuIcon component='img' src={CompaniesIcon} />
        },
        {
            menuItem: 'Online Survey',
            traslationKey: 'onlinesurvey',
            link: '/online-survey',
            menuIcon: (
                <Box
                    component='img'
                    src={SurveyIconWhite}
                    sx={{
                        width: '40px',
                        height: '29px'
                    }}
                />
            ),
            fontClassName: 'font-color-white',
            customButtonStyle: {
                background:variables.BLUE,
                color: `${variables.WHITE1} !important`,
                border: `2px solid ${variables.BLUE}`,
                p: '10px 20px !important',
                alignItems:'center'
            }
        }
    ]

    const candidateOptions = [
        {
            menuItem: 'Dashboard',
            traslationKey: 'home',
            link: '/dashboard',
            menuIcon: <NavMenuIcon component='img' src={HomeIcon} />
        },
        {
            menuItem: 'Jobs',
            traslationKey: 'jobs',
            link: '/job-list',
            menuIcon: <NavMenuIcon component='img' src={JobsIcon} />
        },
        {
            menuItem: 'Companies',
            traslationKey: 'companies',
            link: '/companies',
            menuIcon: <NavMenuIcon component='img' src={CompaniesIcon} />
        },
        {
            menuItem: 'Online Survey',
            traslationKey: 'onlinesurvey',
            link: '/online-survey',
            menuIcon: (
                <Box
                    component='img'
                    src={SurveyIconWhite}
                    sx={{
                        width: '40px',
                        height: '29px'
                    }}
                />
            ),
            fontClassName: 'font-color-white',
            customButtonStyle: {
                background: variables.BLUE,
                color: `${variables.WHITE1} !important`,
                border: `2px solid ${variables.BLUE}`,
                p: '10px 20px !important',
                alignItems:'center'
            }
        }
        // {
        //   menuItem: 'About Us',
        //   traslationKey: 'aboutus',
        //   link: '/about',
        //   menuIcon: <NavMenuIcon component='img' src={BuntonIcon} />
        // },
        // {
        //     menuItem: 'Contact Us',
        //     traslationKey: 'contactus',
        //     link: '/contact-us',
        //     menuIcon: <NavMenuIcon component='img' src={ContactIcon} />
        // }
    ]

    const loggedInSecondaryOptions = [
        {
            traslationKey: 'myprofile',
            link: '/my-profile',
            menuIcon: <NavMenuIcon component='img' src={ProfileIcon} />
        },
        {
            traslationKey: 'settings',
            link: `/settings`,
            menuIcon: <NavMenuIcon component='img' src={SettingsIcon} />
        }
        // {
        //   menuItem: 'About Us',
        //   traslationKey: 'aboutus',
        //   link: '/about',
        //   menuIcon: <NavMenuIcon component='img' src={BuntonIcon} />
        // },
        // {
        //     menuItem: 'Contact Us',
        //     traslationKey: 'contactus',
        //     link: '/contact-us',
        //     menuIcon: <NavMenuIcon component='img' src={ContactIcon} />
        // }
    ]

    const employerOptions = [
        {
            menuItem: 'Dashboard',
            traslationKey: 'home',
            link: '/',
            menuIcon: <NavMenuIcon component='img' src={HomeIcon} />
        },
        {
            menuItem: 'Jobs',
            traslationKey: 'jobs',
            link: '/job-list',
            menuIcon: <NavMenuIcon component='img' src={JobsIcon} />
        }
        // {
        //     menuItem: 'About Us',
        //     traslationKey: 'aboutus',
        //     link: '/about',
        //     menuIcon: <NavMenuIcon component='img' src={BuntonIcon} />
        // },
        // {
        //     menuItem: 'Contact Us',
        //     traslationKey: 'contactus',
        //     link: '/contact-us',
        //     menuIcon: <NavMenuIcon component='img' src={ContactIcon} />
        // }
    ]

    const userOptions = [
        {
            menuItem: 'Login',
            traslationKey: 'login',
            link: '/login'
        },
        {
            menuItem: 'Registration',
            traslationKey: 'register',
            link: '/registration'
        }
    ]

    const adminSecondaryOptions = [
        {
            traslationKey: 'settings',
            link: '/admin-portal/settings',
            menuIcon: <NavMenuIcon component='img' src={ProfileIcon} />
        },
        {
            traslationKey: 'contactusRequest',
            link: '/admin-portal/contact-request',
            menuIcon: <NavMenuIcon component='img' src={ProfileIcon} />
        }

    ]

    const adminOptions = [
        {
            menuItem: 'Dashboard',
            traslationKey: 'home',
            link: '/admin-portal',
            menuIcon: <NavMenuIcon component='img' src={HomeIcon} />
        },
        {
            menuItem: 'Jobs',
            traslationKey: 'jobs',
            link: '/admin-portal/job-list',
            menuIcon: <NavMenuIcon component='img' src={JobsIcon} />
        },
        {
            menuItem: 'Companies',
            traslationKey: 'companies',
            link: '/admin-portal/companies',
            menuIcon: <NavMenuIcon component='img' src={CompaniesIcon} />
        },
        {
            menuItem: 'Users',
            traslationKey: 'users',
            link: '/admin-portal/users',
            menuIcon: <NavMenuIcon component='img' src={UsersIcon} />
        }
        // {
        //   menuItem: 'About Us',
        //   traslationKey: 'aboutus',
        //   link: '/about',
        //   menuIcon: <NavMenuIcon component='img' src={BuntonIcon} />
        // },
        // {
        //     menuItem: 'Contact Us',
        //     traslationKey: 'contactus',
        //     link: '/contact-us',
        //     menuIcon: <NavMenuIcon component='img' src={ContactIcon} />
        // }
    ]

    const options = !isUserAuthenticated
        ? publicOptions
        : userRole === 'user'
        ? candidateOptions
        : userRole === 'admin'
        ? adminOptions
        : employerOptions
    const secondaryOptions = !isUserAuthenticated
        ? userOptions
        : userRole === 'admin'
        ? adminSecondaryOptions
        : loggedInSecondaryOptions

    const handleOpenUserMenu = event => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const userIconSection = () => {
        return (
            <>
                {isUserAuthenticated && userRole !== 'admin' ? (
                    <Box
                        sx={{
                            display: {
                                md: 'none',
                                lg: 'block'
                            },
                            overflow:'hidden'
                        }}
                    >
                        <Typography
                            variant='h5'
                            className='small-size-16px semi-strong'
                            sx={{ fontWeight: '600 !important' }}
                        >
                            {truncateDetails(loginCheckAuthDetails.name, 12)}
                        </Typography>
                    </Box>
                ) : null}
                <Avatar
                    sx={{
                        width: isUserAuthenticated
                            ? '25px'
                            : '35px',
                        height: isUserAuthenticated
                            ? '25px'
                            : '35px',
                        objectFit: 'contain',
                        backgroundColor: '#fff',
                        ml: isUserAuthenticated ? { xs: 0, md: 0, lg: 1 } : 0
                    }}
                    alt='Bunton'
                    src={
                        isUserAuthenticated
                            ? ['user', 'employer'].includes(userRole)
                                ? loginCheckAuthDetails.profileImage
                                    ? userRole === 'employer'
                                        ? loginCheckAuthDetails.company
                                            ? loginCheckAuthDetails.company.companyLogoUrl
                                            : null
                                        : userRole === 'user'
                                        ? getAvatrURL(loginCheckAuthDetails.profileImage)
                                        : DefaultProfilIcon
                                    : DefaultProfilIcon
                                : BuntonIcon
                            : DefaultProfilIcon
                    }
                />
            </>
        )
    }

    return (
        <>
            <AppBar
                position='fixed'
                sx={{
                    backgroundColor: 'transparent'
                }}
            >
                <Container
                    maxWidth={false}
                    sx={{
                        backgroundColor: {
                            xs: variables.WHITE1,
                            md: variables.CYANOPACITY9
                        },
                        backdropFilter: 'blur(2px)',
                        py: 1
                    }}
                >
                    <Toolbar disableGutters>
                        {['mob', 'tab'].includes(responsiveBreakpoint) ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Box sx={{ width: '40%' }}>
                                    <Link to='/'>
                                        <Box
                                            component='img'
                                            src={BuntonLogo}
                                            noWrap
                                            sx={{
                                                width: '100px'
                                            }}
                                        />
                                    </Link>
                                    {/* {userRole === 'admin' ? (
                                    <Typography
                                        sx={{
                                            fontSize: { xs: '15px', md: '23px' },
                                            color: variables.WHITE1,
                                            ml: 2
                                        }}
                                    >
                                        Admin portal
                                    </Typography>
                                ) : null} */}
                                </Box>
                                <Box sx={{ width: '80%', display: 'flex', justifyContent: 'end' }}>
                                    <HomePageButtons
                                        startIcon={
                                            <Box
                                                component='img'
                                                src={SurveyIconBlue}
                                                sx={{
                                                    width: '40px',
                                                    height: '29px'
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                variant='h5'
                                                className={`small-size-16px extrabold`}
                                                sx={{
                                                    ml: '4px'
                                                }}
                                            >
                                                {t('onlinesurvey')}
                                            </Typography>
                                        }
                                        // component='a'
                                        // href={surveyLink}
                                        // target='_blank'
                                        onClick={() => navigate('/online-survey')}
                                        color='secondary'
                                        variant='contained'
                                        size='large'
                                        sx={{
                                            background: variables.CYAN,
                                            color: variables.BLUE,
                                            p: '10px 20px'
                                        }}
                                    />
                                    <MobileMenu
                                        responsiveMenu={true}
                                        containerSx={{
                                            background: variables.CYAN,
                                            borderRadius: '50px',
                                            height: '50px',
                                            width: '50px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item md={2} lg={2} xl={2}>
                                    <Link to='/'>
                                        <Box
                                            component='img'
                                            src={BuntonLogo}
                                            sx={{
                                                width: '120px',
                                                display: 'block'
                                            }}
                                        />
                                    </Link>
                                </Grid>
                                <Grid item md={8} lg={8} xl={8} sx={{ textAlign: 'center' }}>
                                    {options.map((option, index) => (
                                        <HomePageButtons
                                            label={
                                                <Typography
                                                    variant='h5'
                                                    className={`small-size-16px semi-strong ${option.fontClassName ? option.fontClassName : ''}`}
                                                    sx={{
                                                        fontWeight: '600 !important',
                                                        '&:hover': {
                                                            color: `${variables.WHITE1} !important`
                                                        }
                                                    }}
                                                >
                                                    {t(`menu.options.${option.traslationKey}`)}
                                                </Typography>
                                            }
                                            component={Link}
                                            to={option.link}
                                            color='primary'
                                            variant='contained'
                                            size='large'
                                            replace={true}
                                            reloadDocument
                                            sx={{
                                                py: {
                                                    md: 1,
                                                    xl: 2
                                                },
                                                px: {
                                                    md: 2,
                                                    xl: 4
                                                },
                                                alignItems: 'flex-start',
                                                textTransform: 'none',
                                                background: [option.link].includes(
                                                    location.pathname
                                                )
                                                    ? variables.WHITE1
                                                    : 'transparent',
                                                '&:hover': {
                                                    background: 'transparent'
                                                },
                                                ...option.customButtonStyle
                                            }}
                                            startIcon={option.menuIcon}
                                        />
                                    ))}
                                </Grid>
                                <Grid item md={2} lg={2} xl={2} sx={{ textAlign: 'right' }}>
                                    {!isUserAuthenticated ? (
                                        <Box>
                                            {/* {userOptions.map(option => (
                                            <HomePageButtons
                                                label={
                                                    <Typography
                                                        variant='h5'
                                                        className='small-size-16px semi-strong'
                                                        sx={{
                                                            fontWeight: '600 !important',
                                                            '&:hover': {
                                                                color: `${variables.WHITE1} !important`
                                                            }
                                                        }}
                                                    >
                                                        {t(`menu.options.${option.traslationKey}`)}
                                                    </Typography>
                                                }
                                                component={Link}
                                                to={option.link}
                                                color='primary'
                                                variant='contained'
                                                size='large'
                                                sx={{
                                                    py: 2,
                                                    px: 4,
                                                    alignItems: 'flex-start',
                                                    textTransform: 'none',
                                                    background: variables.WHITE1,
                                                    '&:hover': {
                                                        background: variables.BLUE
                                                    }
                                                }}
                                                // startIcon={option.menuIcon}
                                            />
                                        ))} */}
                                            <Tooltip>
                                                <MuiButton
                                                    onClick={handleOpenUserMenu}
                                                    sx={{
                                                        background: variables.WHITE1,
                                                        py: 1,
                                                        px: isUserAuthenticated ? 2 : 1,
                                                        borderRadius: '50px',
                                                        height: '50px',
                                                        width: '50px',
                                                        minWidth: '50px',
                                                        cursor: 'pointer',
                                                        textTransform: 'none',
                                                        '&:hover': {
                                                            background: variables.WHITE1,
                                                            color: variables.BLUE
                                                        }
                                                    }}
                                                >
                                                    {userIconSection()}
                                                </MuiButton>
                                            </Tooltip>
                                            <Menu
                                                sx={{ mt: '60px' }}
                                                id='menu-appbar'
                                                anchorEl={anchorElUser}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right'
                                                }}
                                                open={Boolean(anchorElUser)}
                                                onClose={handleCloseUserMenu}
                                                PaperProps={{
                                                    elevation: 0,
                                                    sx: {
                                                        background: variables.CYAN,
                                                        minWidth: '200px'
                                                    }
                                                }}
                                            >
                                                {userOptions.map((option, index) => (
                                                    <MenuItem
                                                        key={index}
                                                        onClick={() => {
                                                            navigate(option.link)
                                                            handleCloseUserMenu()
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                py: 2,
                                                                px: 1,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'right',
                                                                width: '100%'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='h3'
                                                                className='small-size-18px'
                                                                sx={{
                                                                    textAlign: 'right',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                {t(
                                                                    `menu.options.${option.traslationKey}`
                                                                )}
                                                            </Typography>
                                                            <Box component='span' sx={{ ml: 1 }}>
                                                                {option.menuIcon}
                                                            </Box>
                                                        </Box>
                                                    </MenuItem>
                                                ))}
                                                {isUserAuthenticated ? (
                                                    <MenuItem
                                                        onClick={() => {
                                                            navigate('/logout')
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                py: 2,
                                                                px: 1,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'right',
                                                                width: '100%'
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='h3'
                                                                className='small-size-18px'
                                                            >
                                                                {t('menu.options.logout')}
                                                            </Typography>
                                                            <LogoutIcon
                                                                sx={{ fontSize: '20px', ml: 1 }}
                                                            />
                                                        </Box>
                                                    </MenuItem>
                                                ) : null}
                                            </Menu>
                                        </Box>
                                    ) : (
                                        <Box sx={{ display: 'flex', float: 'right' }}>
                                            <LanguageSwitcher
                                                sx={{
                                                    mr: 1,
                                                    p: '4px',
                                                    minWidth: 'fit-content'
                                                }}
                                                flagSx={{
                                                    height: '33px',
                                                    width: '33px'
                                                }}
                                            />
                                            {/* {location.pathname === '/dashboard' && (
                                                <Box sx={{ mr: 2 }}>
                                                    <ShareJobButton
                                                        color='primary'
                                                        variant='contained'
                                                        iconEnable={true}
                                                        textEnable={false}
                                                        disableRipple={true}
                                                        onClick={() => {
                                                            setShowShareModal(true)
                                                        }}
                                                        sx={{
                                                            width: '41px',
                                                            height: '41px',
                                                            minWidth: '41px',
                                                            p: '9px',
                                                            '&:hover': {
                                                                background: variables.WHITE1
                                                            }
                                                        }}
                                                        iconStyle={{
                                                            ml: 0,
                                                            height: '28px',
                                                            width: '28px'
                                                        }}
                                                    />
                                                    <ShareModal
                                                        open={showShareModal}
                                                        handleClose={() => setShowShareModal(false)}
                                                        shareJob={false}
                                                        userId={userId}
                                                        showThankYou={() => {
                                                            setThanksModal(true)
                                                            setTimeout(() => {
                                                                setThanksModal(false)
                                                            }, 3000)
                                                        }}
                                                    />
                                                    <ThankyouModal
                                                        message='Thank you for sharing!'
                                                        open={thanksModal}
                                                        handleClose={() => setThanksModal(false)}
                                                    />
                                                </Box>
                                            )} */}

                                            {/* <Link to={'/job-list'}>
                          <Button
                              label='Find Jobs'
                              color='primary'
                              variant='contained'
                              size='large'
                              sx={{
                                  padding: '13px 30px',
                                  fontSize: '14px',

                                  fontWeight: '600',
                                  marginLeft: '15px',
                                  marginRight: '18px'
                              }}
                          />
                      </Link> */}
                                            <Box>
                                                <Tooltip>
                                                    <MuiButton
                                                        onClick={handleOpenUserMenu}
                                                        sx={{
                                                            background: variables.WHITE1,
                                                            py: 1,
                                                            px: 1,
                                                            borderRadius: '50px',
                                                            height: {
                                                                md: '41px',
                                                                xl:
                                                                    userRole !== 'admin'
                                                                        ? 'auto'
                                                                        : '41px'
                                                            },
                                                            width: {
                                                                md: '41px',
                                                                xl:
                                                                    userRole !== 'admin'
                                                                        ? 'max-content'
                                                                        : '41px'
                                                            },
                                                            minWidth: {
                                                                md: '41px',
                                                                lg:
                                                                    userRole !== 'admin'
                                                                        ? '156px'
                                                                        : '41px'
                                                            },
                                                            maxWidth: '180px',
                                                            maxHeight: {
                                                                md: '41px',
                                                                xl: '55px'
                                                            },
                                                            cursor: 'pointer',
                                                            textTransform: 'none',
                                                            '&:hover': {
                                                                background: variables.WHITE1,
                                                                color: variables.BLUE
                                                            }
                                                        }}
                                                    >
                                                        {userIconSection()}
                                                    </MuiButton>
                                                </Tooltip>
                                                <Menu
                                                    sx={{ mt: '60px' }}
                                                    id='menu-appbar'
                                                    anchorEl={anchorElUser}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                    keepMounted
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right'
                                                    }}
                                                    open={Boolean(anchorElUser)}
                                                    onClose={handleCloseUserMenu}
                                                    PaperProps={{
                                                        elevation: 0,
                                                        sx: {
                                                            background: variables.CYANOPACITY9,
                                                            backdropFilter: 'blur(10px)',
                                                            minWidth: '200px'
                                                        }
                                                    }}
                                                >
                                                    {secondaryOptions.map((option, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            onClick={() => {
                                                                navigate(option.link)
                                                                handleCloseUserMenu()
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    py: 2,
                                                                    px: 1,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'right',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant='h3'
                                                                    className='small-size-18px'
                                                                    sx={{
                                                                        textAlign: 'right',
                                                                        width: '100%',
                                                                        mr: 1
                                                                    }}
                                                                >
                                                                    {t(
                                                                        `menu.options.${option.traslationKey}`
                                                                    )}
                                                                </Typography>
                                                                {option.menuIcon}
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                    {isUserAuthenticated ? (
                                                        <MenuItem
                                                            onClick={() => {
                                                                navigate('/logout')
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    py: 2,
                                                                    px: 1,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'right',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant='h3'
                                                                    className='small-size-18px'
                                                                >
                                                                    {t('menu.options.logout')}
                                                                </Typography>
                                                                <LogoutIcon
                                                                    sx={{ fontSize: '20px', ml: 1 }}
                                                                />
                                                            </Box>
                                                        </MenuItem>
                                                    ) : (
                                                        <Box></Box>
                                                    )}
                                                </Menu>
                                            </Box>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Toolbar>
                </Container>
                {((!['mob', 'tab'].includes(responsiveBreakpoint) && !isUserAuthenticated) ||
                    (!isUserAuthenticated &&
                        [('mob', 'tab')].includes(responsiveBreakpoint) &&
                        location.pathname !== '/')) &&
                process.env.REACT_APP_SURVEY_LINK === 'true' ? (
                    <SurveyMenuFlag />
                ) : null}
                {process.env.REACT_APP_SURVEY_LINK === 'true' &&
                isUserAuthenticated &&
                !['admin', 'employer'].includes(userRole) ? (
                    <SurveyMenuFlag loggedIn={true} />
                ) : null}
            </AppBar>
        </>
    )
}
export default ResponsiveAppBar
