import { Box, Grid, Modal } from '@mui/material'
import React, { useState } from 'react'
import JanetRecommendation from '../../assets/images/Janet_Rec_banner_home.png'
import AboutBanner from '../../assets/images/About_banner.png'
import FazBanner from '../../assets/images/FAZ.png'
import { SectionContainer } from '../../pages/HomePage'
import { Link } from 'react-router-dom'
import FazArticle from '../../assets/images/bunton_FAZ_ARTICLE.png'
import variables from '../../settings/_variables.scss'

const FounderSection = props => {
    const [openFazModal, setOpenFazModal] = useState(false)
    const { aboutBunton } = props
    return (
        <SectionContainer maxWidth='1520px' sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid
                container
                columnSpacing={{ xs: 0, md: 2 }}
                rowSpacing={{ xs: 2, md: 0 }}
                sx={{ maxWidth: '1520px', textAlign: 'center' }}
                justifyContent='center'
            >
                <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                    <Link to='/janet-recommendation'>
                        <Box
                            component='img'
                            src={JanetRecommendation}
                            sx={{
                                maxWidth: '100%',
                                width: '100%',
                                '&:hover': {
                                    border: `2px solid ${variables.BLUE}`
                                },
                                borderRadius: '30px'
                            }}
                        />
                    </Link>
                </Grid>
                <Grid item md={6} lg={6} xl={6} sm={12} xs={12}>
                    {aboutBunton ? (
                        <Link to='/about'>
                            <Box
                                component='img'
                                src={AboutBanner}
                                sx={{
                                    maxWidth: '100%',
                                    width: '100%',
                                    '&:hover': {
                                        border: `2px solid ${variables.BLUE}`
                                    },
                                    borderRadius: '30px'
                                }}
                            />
                        </Link>
                    ) : (
                        <>
                            <Box
                                onClick={() => setOpenFazModal(true)}
                                component='img'
                                src={FazBanner}
                                sx={{
                                    maxWidth: '100%',
                                    width: '100%',
                                    '&:hover': {
                                        border: `2px solid ${variables.BLUE}`
                                    },
                                    borderRadius: '30px'
                                }}
                            />
                            <Modal
                                open={openFazModal}
                                onClose={() => setOpenFazModal(false)}
                                aria-labelledby='Faz-publication'
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: variables.WHITEOPACITY,
                                    position: 'fixed',
                                    top: '0',
                                    left: '0',
                                    backdropFilter: 'blur(2px)',
                                    zIndex: 9
                                }}
                                slotProps={{
                                    backdrop: {
                                        sx: {
                                            background: variables.CYAN,
                                            opacity: '0.7 !important'
                                        },
                                        timeout: 500
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: {
                                            xs: '90%',
                                            sm: '90%',
                                            md: '90%',
                                            lg: '80%',
                                            xl: '50%'
                                        },
                                        minHeight: '300px'
                                    }}
                                >
                                    <Box
                                        component='img'
                                        src={FazArticle}
                                        sx={{ height: '100%', width: '100%' }}
                                    />
                                </Box>
                            </Modal>
                        </>
                    )}
                </Grid>
            </Grid>
        </SectionContainer>
    )
}

export default FounderSection
