import { Box, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import BuntonRocketImage from '../../assets/images/BUN_Rocket.png'
import { SectionContainer } from '../../pages/HomePage'
import { useSelector } from 'react-redux'
import variables from '../../settings/_variables.scss'

const LandingPageJoinUs = props => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)

    const getDivider = () => {
        return ['mob', 'tab'].includes(responsiveBreakpoint) ? <Divider
            variant='middle'
            sx={{
                border: `1px solid ${variables.CYAN}`,
                m: 0,
                height: '0px',
                width: {xs: '100px', md: '0'},
                margin:'32px auto',
            }}
        /> : null
    }
    return (
        <SectionContainer maxWidth='1520px' disableGutters>
            <Grid
                container
                spacing={0}
                sx={{ m: '0 auto', maxWidth: '1520px' }}
                justifyContent='center'
                
            >
                {!['mob', 'tab'].includes(responsiveBreakpoint) && (
                    <Grid item xs={0} sm={0} md={3} lg={2} xl={2} sx={{ textAlign: 'left' }}>
                        <Box
                            component='img'
                            src={BuntonRocketImage}
                            alt='rep'
                            sx={{
                                width: {
                                    xs: '120px',
                                    md: '140px',
                                    lg: '150px',
                                    xl: '170px'
                                }
                            }}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
                    <Grid
                        container
                        sx={{
                            background: variables.BLUE,
                            minHeight: 119,
                            borderRadius: 10,
                            py: { xs: 0, md: 4 },
                            px: { xs: 4, md: 0 }
                        }}
                    >
                        <Grid
                            item
                            md={4}
                            lg={4}
                            xl={4}
                            sm={12}
                            xs={12}
                            sx={{ pt: { xs: 4, md: 0 } }}
                        >
                            <Box sx={{ textAlign: 'center', mx: 'auto' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography
                                        variant='paragraph'
                                        className='para-font-15px font-color-white'
                                    >
                                        aktuell
                                    </Typography>
                                    <Typography
                                        variant='h2'
                                        className='font-size-30px strong cyan-color'
                                        sx={{ ml: 1 }}
                                    >
                                        300
                                    </Typography>
                                </Box>
                                <Typography
                                    variant='paragraph'
                                    className='para-font-15px font-color-white'
                                >
                                    offene Leadershipstellen
                                </Typography>
                            </Box>
                            {getDivider()}
                        </Grid>
                        <Grid
                            item
                            md={4}
                            lg={4}
                            xl={4}
                            sm={12}
                            xs={12}
                            sx={{
                                borderLeft: {
                                    xs: 0,
                                    md: `2px solid ${variables.CYAN}`
                                },
                            }}
                        >
                            <Box sx={{ textAlign: 'center', mx: 'auto' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography
                                        variant='paragraph'
                                        className='para-font-15px font-color-white'
                                    >
                                        über
                                    </Typography>
                                    <Typography
                                        variant='h2'
                                        className='font-size-30px strong cyan-color'
                                        sx={{ ml: 1 }}
                                    >
                                        70%
                                    </Typography>
                                </Box>
                                <Typography
                                    variant='paragraph'
                                    className='para-font-15px font-color-white'
                                >
                                    Erfolgsquote bei Bewerbungen
                                </Typography>
                            </Box>
                            {getDivider()}
                        </Grid>
                        <Grid
                            item
                            md={4}
                            lg={4}
                            xl={4}
                            sm={12}
                            xs={12}
                            sx={{
                                borderLeft: {
                                    xs: 0,
                                    md: `2px solid ${variables.CYAN}`
                                },
                                pb: { xs: 4, md: 0 } 
                            }}
                        >
                            <Box sx={{ textAlign: 'center', mx: 'auto' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Typography
                                        variant='paragraph'
                                        className='para-font-15px font-color-white'
                                    >
                                        mehr als
                                    </Typography>
                                    <Typography
                                        variant='h2'
                                        className='font-size-30px strong cyan-color'
                                        sx={{ ml: 1 }}
                                    >
                                        800
                                    </Typography>
                                </Box>
                                <Typography
                                    variant='paragraph'
                                    className='para-font-15px font-color-white'
                                >
                                    Mitgliedsunternehmen
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SectionContainer>
    )
}

export default LandingPageJoinUs
